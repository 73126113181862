import { useNavigate } from "@solidjs/router";
import { Text, View, hexColor } from "@lightningtv/solid";
import packageJson from "../../../package.json";
const AboutApp = () => {
  const navigate = useNavigate();
  return (
    <View
      color={hexColor("#000000")}
      onEscape={() => {
        navigate("/home");
      }}
    >
      <View x={650} y={150}>
        <View
          style={{
            width: 600,
            height: 600,
            borderRadius: 15,
            transition: {
              scale: { duration: 250, easing: "ease-in-out" },
            },
          }}
          src="https://i.ibb.co/NmKZfFn/orbixplay-logo.png"
        />
        <Text x={230} y={400} style={{ fontSize: 20, color: hexColor("#ffffff") }}>
          Version: {packageJson.version}
        </Text>
      </View>
    </View>
  );
};
export default AboutApp;
