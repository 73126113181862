import { ElementNode, Text, View, Show, hexColor, IntrinsicTextNodeStyleProps } from "@lightningtv/solid";
import { Column, Button, ButtonContainer } from "@lightningtv/solid-ui";
import { useParams } from "@solidjs/router";
import {
  createEffect,
  createResource,
  createSignal,
  createSelector,
  //@ts-ignore
  For,
  createMemo,
} from "solid-js";
import { setIsPlaying } from "../../state";
import { useNavigate } from "@solidjs/router";
import styles from "../styles";
import * as provider from "../api/providers/entity";
import { assertTruthy } from "@lightningjs/renderer/utils";
import type { Tile } from "../api/formatters/ItemFormatter";
import videoPlayer from "../video";
import { createInfiniteScroll } from "../components/pagination";
import episodesProvider from "../api/providers/episodesProvider";
import ContentBlockSeries from "../components/ContentBlockSeries";
import LoadingIndicator from "./loading/loading";

const RowStyles: any = {
  display: "flex",
  justifyContent: "flexStart",
  width: 300,
  height: 300,
  color: hexColor("00000000"),
  gap: 26,
  y: -110,
} satisfies IntrinsicTextNodeStyleProps;

const SerieDetails = () => {
  const params = useParams();

  const navigate = useNavigate();
  const [movieName, setMovieName] = createSignal(null);
  const [isVideoPlaying, setIsVideoPlaying] = createSignal(false);
  const [valueOfStream, setValueOfStream] = createSignal(null);
  const [backgroundImageEntity, setBackGroundImage] = createSignal("");

  const [backdropAlpha, setBackdropAlpha] = createSignal(0);
  const [isNumberChanging, setIsNumberChanging] = createSignal(false);
  const [number, setNumber] = createSignal(null);
  const [index, setIndex] = createSignal(0);

  const [data] = createResource(() => ({ ...params }), provider.getInfo);

  const [recommendations] = createResource<any, Tile[]>(() => ({ ...params }), provider.getRecommendations);

  const [seasons] = createResource<any, Tile[]>(() => ({ ...params }), provider.getSeriesSeasons);

  createEffect(() => {
    if (seasons()) {
      //@ts-ignore
      setNumber(params.id);
    }
  });

  createEffect(() => {
    const currentData = data();
    if (currentData) {
      setBackGroundImage(currentData.heroContent.coverImage());
      setMovieName(currentData.heroContent.title());
      //@ts-ignore
      setValueOfStream(currentData.idOfStream);
    }
  }, [data()]);

  const columnY = 640;

  const Backdrop = {
    color: hexColor("#000000"),
    alpha: 0,
    width: 1900,
    height: 890,
    x: -160,
    y: columnY,
    borderRadius: 30,
  };

  function onRowFocus(this: ElementNode) {
    //@ts-ignore
    this.children.selected?.setFocus();
    columnRef.y = columnY;
    backdropRef.y = columnY;
    backdropRef.alpha = 0;
  }

  function onEnter(this: ElementNode) {
    //@ts-ignore
    let entity = this.children.selected;
    assertTruthy(entity && entity.href);
    navigate(entity.href);
  }

  const onEscape = () => {
    if (isVideoPlaying() === true) {
      videoPlayer
        .destroy()
        .then(() => {
          console.log("Video player destroyed and video element removed.");
        })
        .catch(error => {
          console.error("Error removing video player:", error);
        });
      setIsVideoPlaying(false);
    } else {
      setIsVideoPlaying(false);

      // // Set focus back to lightning app
      document.getElementsByTagName("canvas")[0].focus();
      // entityActions.setFocus();
      // setBackdropAlpha(0);
      setTimeout(() => navigate(-1), 100);
      setIsPlaying(true);
    }
  };

  const onEnterSeries = (idSeries: any, title: any) => {
    navigate(`/player?str=${idSeries}&type=series&name=${title}`);
  };

  function onEnterTrailer(context: any) {
    const streamOfID = valueOfStream();
    const nameOfMovie = movieName();
    navigate(`/player?str=${streamOfID}&type=movie&name=${nameOfMovie}`);
  }

  let columnRef, backdropRef, entityActions, seriesRow;
  let myButton;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const handleNumberChange = (newNumber: any) => {
    if (index() === newNumber()) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsNumberChanging(true);
    timeoutId = setTimeout(() => {
      setIndex(newNumber());
      setIsNumberChanging(false);
      timeoutId = null;
    }, 500);
  };

  const providerOfSeries = createMemo(() => {
    //@ts-ignore
    return createInfiniteScroll(episodesProvider(params.filter || "all", number(), index()));
  });

  const isFirst = createSelector(() => {
    return 0;
  });

  function DemoTile(props) {
    return (
      <ButtonContainer
        onEnter={() => {
          onEnterSeries(props.id, props.title);
        }}
        states={"active"}
        //@ts-ignore
        style={{
          borderRadius: 7,
          width: 530,
          height: 85,
          scale: 1,
          zIndex: 2,
          color: hexColor("#222222"),
          transition: { scale: { duration: 250, easing: "ease-in-out" } },
          border: { width: 0, color: 0x00000000 },
          focus: { scale: 1.1, color: hexColor("#128DD3") },
        }}
      >
        <View>
          <View src={props.info.movie_image} style={{ width: 80, borderRadius: 10 }} />

          <Text x={90} y={30} style={{ fontSize: 28 }}>
            {props.title.length > 30 ? props.title.slice(0, 30) + "..." : props.title}
          </Text>
        </View>
      </ButtonContainer>
    );
  }

  return (
    <Show when={data()}>
      {/* <View src={`${backgroundImageEntity()[0]}`}> */}
      <View
        onEscape={() => {
          onEscape();
        }}
        color={hexColor("#000000")}
      >
        <View
          onLeft={() => {
            return true;
          }}
          x={170}
        >
          <ContentBlockSeries
            y={80}
            x={params.type == "series" ? -100 : 0}
            content={data().heroContent}
          ></ContentBlockSeries>

          <Text x={680} y={50} style={{ fontSize: 35, fontWeight: "bold" }}>
            Seasons
          </Text>
          <Text x={1160} y={50} style={{ fontSize: 35, fontWeight: "bold" }}>
            Episodes
          </Text>

          <Show
            when={seasons() && seasons().length > 0}
            fallback={
              <View x={1000} y={450}>
                <LoadingIndicator isCategoriesIndicator={true} />
              </View>
            }
          >
            <View x={680} y={100} clipping>
              <Column
                transition={{ x: { duration: 350 } }}
                ref={myButton}
                onRight={() => {
                  seriesRow.setFocus();
                }}
                gap={20}
                style={RowStyles}
                y={50}
                scroll="always"
              >
                <For each={seasons()}>
                  {(categorie, i) => {
                    return (
                      <Button
                        transition={{ x: { duration: 350 } }}
                        width={410}
                        height={70}
                        borderRadius={10}
                        //@ts-ignore``
                        style={{ color: hexColor("#222222"), focus: { color: hexColor("#128DD3") } }}
                        autofocus={isFirst(i())}
                        index={i()}
                        states={"active"}
                        onEnter={() => {
                          handleNumberChange(i);
                        }}
                      >
                        {categorie}
                      </Button>
                    );
                  }}
                </For>
              </Column>

              <Show
                when={providerOfSeries().pages() && !isNumberChanging()}
                fallback={
                  <View x={480} y={80}>
                    <Text style={{ fontSize: 25 }}>No episodes available</Text>
                  </View>
                }
              >
                <Column
                  ref={seriesRow}
                  onLeft={() => {
                    myButton.setFocus();
                  }}
                  plinko
                  announce="Series"
                  style={styles.Column}
                  x={480}
                  gap={15}
                  selected={0}
                  y={48}
                >
                  <For each={providerOfSeries().pages()}>
                    {(demo, i) => (
                      <DemoTile
                        onEnter={() => {
                          onEnterTrailer("movies");
                        }}
                        index={i()}
                        {...demo}
                      />
                    )}
                  </For>
                </Column>
              </Show>
            </View>
          </Show>
          <View ref={backdropRef} style={Backdrop} transition={{ alpha: true, y: true }} />
        </View>
        <View alpha={backdropAlpha()} color={hexColor("#000000")} zIndex={200} transition={{ alpha: true }} />
        {/* </View> */}
      </View>
      {/* </View> */}
    </Show>
  );
};

export default SerieDetails;
