import { Show, createEffect, createSignal, onCleanup } from "solid-js";
import { useNavigate, useParams } from "@solidjs/router";
import { Text, View, hexColor } from "@lightningtv/solid";
const SettingsPage = () => {
  let userInfoRef;
  let aboutAppRef;
  const navigate = useNavigate();

  createEffect(() => {
    localStorage.setItem("@emptyCategory", "true");
    userInfoRef.setFocus();
  });

  return (
    <View
      color={hexColor("#000000")}
      onEscape={() => {
        navigate("/home");
      }}
    >
      <View
        ref={el => {
          userInfoRef = el;
        }}
        x={100}
        y={70}
        style={{
          width: 250,
          height: 250,
          borderRadius: 15,
          color: hexColor("#222222"),
          focus: { color: hexColor("#128DD3") },
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
        }}
        onRight={() => aboutAppRef.setFocus()}
        onEnter={() => navigate("/userInfo")}
      >
        <View x={75} y={50} width={95} height={95} src="https://i.ibb.co/L89F97B/profileuser.png" />
        <Text x={75} y={170} style={{ fontSize: 22, color: hexColor("#ffffff") }}>
          User Info
        </Text>
      </View>
      <View
        ref={el => {
          aboutAppRef = el;
        }}
        x={380}
        y={70}
        style={{
          focus: { color: hexColor("#128DD3") },
          width: 250,
          height: 250,
          borderRadius: 15,
          color: hexColor("#222222"),
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
        }}
        onLeft={() => userInfoRef.setFocus()}
        onEnter={() => navigate("/aboutApp")}
      >
        <View x={75} y={50} width={97} height={97} src="https://i.ibb.co/qdgwnkT/info.png" />
        <Text x={75} y={170} style={{ fontSize: 22, color: hexColor("#ffffff") }}>
          About App
        </Text>
      </View>
    </View>
  );
};
export default SettingsPage;
