import { ElementNode, Text, View, Show, hexColor, IntrinsicTextNodeStyleProps } from "@lightningtv/solid";
import { Column, Button, Row, ButtonContainer } from "@lightningtv/solid-ui";
import { useParams } from "@solidjs/router";
import {
  createEffect,
  createResource,
  on,
  createSignal,
  createSelector,
  //@ts-ignore
  For,
  createMemo,
} from "solid-js";
import { TileRow, TileRowMovie, TileRowRecomandation } from "../components";
import {
  playListPassword,
  playListUrl,
  playListUsername,
  setGlobalBackground,
  setGlobalStream,
  setGlobalStreamType,
  setIsPlaying,
} from "../../state";
import ContentBlock from "../components/ContentBlock";
import { useNavigate } from "@solidjs/router";
import styles from "../styles";
import * as provider from "../api/providers/entity";
import { assertTruthy } from "@lightningjs/renderer/utils";
import type { Tile } from "../api/formatters/ItemFormatter";
import videoPlayer from "../video";
import axios from "axios";
import { createInfiniteScroll } from "../components/pagination";
import moviesProvider from "../api/providers/episodesProvider";
import episodesProvider from "../api/providers/episodesProvider";

const RowStyles: any = {
  display: "flex",
  justifyContent: "flexStart",
  width: 300,
  height: 300,
  color: hexColor("00000000"),
  gap: 26,
  y: -110,
} satisfies IntrinsicTextNodeStyleProps;

const Entity = () => {
  const params = useParams();

  const navigate = useNavigate();
  const [movieName, setMovieName] = createSignal(null);
  const [isVideoPlaying, setIsVideoPlaying] = createSignal(false);
  const [valueOfStream, setValueOfStream] = createSignal(null);
  const [backgroundImageEntity, setBackGroundImage] = createSignal("");

  const [backdropAlpha, setBackdropAlpha] = createSignal(0);
  const [isNumberChanging, setIsNumberChanging] = createSignal(false);
  const [number, setNumber] = createSignal(null);
  const [index, setIndex] = createSignal(0);

  const [data] = createResource(() => ({ ...params }), provider.getInfo);

  const [recommendations] = createResource<any, Tile[]>(() => ({ ...params }), provider.getRecommendations);

  const [seasons] = createResource<any, Tile[]>(() => ({ ...params }), provider.getSeriesSeasons);

  createEffect(() => {
    if (seasons()) {
      //@ts-ignore
      setNumber(params.id);
    }
  });

  createEffect(() => {
    const currentData: any = data();
    if (currentData) {
      console.log("currentData", currentData.backgroundImage()[0]);
      setBackGroundImage(currentData.backgroundImage());
      setMovieName(currentData.heroContent.title());

      //@ts-ignore
      setValueOfStream(currentData.idOfStream);
    }
  }, [data()]);

  const columnY = 640;

  const Backdrop = {
    color: hexColor("#000000"),
    alpha: 0,
    width: 1900,
    height: 890,
    x: -160,
    y: columnY,
    borderRadius: 30,
  };

  function onRowFocus(this: ElementNode) {
    //@ts-ignore
    this.children.selected?.setFocus();
    columnRef.y = columnY;
    backdropRef.y = columnY;
    backdropRef.alpha = 0;
  }

  function onEnter(this: ElementNode) {
    entityActions.setFocus();
    //@ts-ignore
    let entity = this.children.selected;
    assertTruthy(entity && entity.href);
    navigate(entity.href);
    // console.log(credits());
  }

  const onEscape = () => {
    if (isVideoPlaying() === true) {
      videoPlayer
        .destroy()
        .then(() => {
          console.log("Video player destroyed and video element removed.");
        })
        .catch(error => {
          console.error("Error removing video player:", error);
        });
      setIsVideoPlaying(false);
    } else {
      setIsVideoPlaying(false);

      // // Set focus back to lightning app
      document.getElementsByTagName("canvas")[0].focus();
      // entityActions.setFocus();
      // setBackdropAlpha(0);
      setTimeout(() => navigate(-1), 100);
      setIsPlaying(true);
    }
  };

  const onEnterSeries = (idSeries: any, title: any) => {
    navigate(`/player?str=${idSeries}&type=series&name=${title}`);
  };

  function onEnterTrailer(context: any) {
    const streamOfID = valueOfStream();
    const nameOfMovie = movieName();
    navigate(`/player?str=${streamOfID}&type=movie&name=${nameOfMovie}`);
  }

  let columnRef, backdropRef, entityActions, seriesRow;
  let myButton;
  let timeoutId: ReturnType<typeof setTimeout> | null = null;

  const handleNumberChange = (newNumber: any) => {
    if (index() === newNumber()) {
      return;
    }
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setIsNumberChanging(true);
    timeoutId = setTimeout(() => {
      setIndex(newNumber());
      setIsNumberChanging(false);
      timeoutId = null;
    }, 500);
  };

  const providerOfSeries = createMemo(() => {
    //@ts-ignore
    return createInfiniteScroll(episodesProvider(params.filter || "all", number(), index()));
  });

  const isFirst = createSelector(() => {
    return 0;
  });

  function DemoTile(props) {
    return (
      <ButtonContainer
        onEnter={() => {
          onEnterSeries(props.id, props.title);
        }}
        states={"active"}
        //@ts-ignore
        style={{
          borderRadius: 7,
          width: 500,
          height: 85,
          scale: 1,
          zIndex: 2,
          color: 0x4f7f4f,
          transition: { scale: { duration: 250, easing: "ease-in-out" } },
          border: { width: 0, color: 0x00000000 },
          focus: { scale: 1.1, color: "0x467ffa" },
        }}
      >
        <Text style={{ fontSize: 28 }}>
          {props.title.length > 30 ? props.title.slice(0, 30) + "..." : props.title}
        </Text>
      </ButtonContainer>
    );
  }

  return (
    <Show when={data()}>
      <View src={`${backgroundImageEntity()[0]}`}>
        <View
          onEscape={() => {
            onEscape();
          }}
          src={"https://i.ibb.co/NrtvBBD/hero-mask-inverted.png"}
        >
          <View color={backgroundImageEntity()[0] ? hexColor("#00000000") : hexColor("#000000")}>
            <View
              onLeft={() => {
                return true;
              }}
              x={170}
            >
              <ContentBlock
                y={160}
                x={params.type == "series" ? -100 : 0}
                content={data().heroContent}
              ></ContentBlock>
              <Row
                ref={entityActions}
                y={450}
                scroll="none"
                height={90}
                width={640}
                gap={40}
                onDown={() => {
                  if (params.type === "movie") {
                    columnRef.setFocus();
                  } else {
                    myButton.setFocus();
                  }
                }}
                onEnter={() => {
                  onEnterTrailer("movies");
                }}
              >
                {params.type === "movie" && (
                  <Button
                    style={{ color: "#222222", focus: { color: "#128DD3" }, borderRadius: 15 }}
                    width={300}
                    autofocus
                  >
                    PLAY
                  </Button>
                )}
              </Row>

              <Show
                when={params.type === "movie"}
                fallback={
                  <View x={830} y={100}>
                    <Column
                      transition={{ x: { duration: 350 } }}
                      ref={myButton}
                      onRight={() => {
                        seriesRow.setFocus();
                      }}
                      gap={20}
                      style={RowStyles}
                      y={50}
                    >
                      <For each={seasons()}>
                        {(categorie, i) => {
                          return (
                            <Button
                              transition={{ x: { duration: 350 } }}
                              //@ts-ignore``
                              style={{ color: 0x4f7f4f, focus: { color: "0x467ffa" } }}
                              autofocus={isFirst(i())}
                              index={i()}
                              states={"active"}
                              onFocus={() => {
                                handleNumberChange(i);
                              }}
                            >
                              {categorie}
                            </Button>
                          );
                        }}
                      </For>
                    </Column>
                    <Show when={providerOfSeries().pages() && !isNumberChanging()}>
                      <Column
                        ref={seriesRow}
                        onLeft={() => {
                          myButton.setFocus();
                        }}
                        plinko
                        announce="Series"
                        style={styles.Column}
                        x={350}
                        gap={15}
                        selected={0}
                        y={48}
                      >
                        <For each={providerOfSeries().pages()}>
                          {(demo, i) => (
                            <DemoTile
                              onEnter={() => {
                                onEnterTrailer("movies");
                              }}
                              index={i()}
                              {...demo}
                            />
                          )}
                        </For>
                      </Column>
                    </Show>
                  </View>
                }
              >
                <Column
                  ref={columnRef}
                  x={0}
                  y={columnY}
                  style={styles.Column}
                  scroll="none"
                  zIndex={5}
                  onUp={() => entityActions.setFocus()}
                >
                  <Show when={recommendations()}>
                    <Text skipFocus style={styles.RowTitle}>
                      Recommendations
                    </Text>
                    <TileRowRecomandation
                      onFocus={onRowFocus}
                      onEnter={onEnter}
                      items={recommendations()}
                      width={1620}
                    />
                  </Show>
                </Column>
              </Show>

              <View ref={backdropRef} style={Backdrop} transition={{ alpha: true, y: true }} />
            </View>
            <View
              alpha={backdropAlpha()}
              color={hexColor("#000000")}
              zIndex={200}
              transition={{ alpha: true }}
            />
          </View>
        </View>
      </View>
    </Show>
  );
};

export default Entity;
