import { onCleanup, onMount } from "solid-js";
import { gsap } from "gsap";

const LoadingIndicator = (props) => {
  let gifElement;


  console.log("props", props.isCategoriesIndicator);


  onMount(() => {
    // Set the rotation to a complete turn (360 degrees) without acceleration
    const animation = gsap.to(gifElement, {
      duration: 30, // Duration for a full rotation
      rotation: 360, // Complete rotation
      repeat: -1, // Repeat indefinitely
      ease: "linear", // Use linear easing for constant speed
    });

    onCleanup(() => {
      animation.kill();
    });
  });

  return (
    <div>
      <img
        ref={gifElement}
        src="https://i.ibb.co/3pcbRDW/loading-1.png"
        alt="Animated GIF"
        width={props.isCategoriesIndicator ? " 150" : "250"}
        height={props.isCategoriesIndicator ? " 150" : "250"}
      />
    </div>
  );
};

export default LoadingIndicator;
