import {
  createEffect,
  createSignal,
  //@ts-ignore
  Show,
  createSelector,
  //@ts-ignore
  For,
  onCleanup,
  onMount,
} from "solid-js";
import { useLocation, useNavigate } from "@solidjs/router";
import { View, ElementNode, hexColor } from "@lightningtv/solid";
import { useFocusManager, useAnnouncer, useMouse } from "@lightningtv/solid/primitives";
import CryptoJS from "crypto-js";
import axios from "axios";
import SplashScreen from "./splashScreen/SplashScreen";

declare module "@lightningtv/solid/primitives" {
  interface KeyMap {
    Announcer: (string | number)[];
    Menu: (string | number)[];
    // Escape: (string | number)[];
    Backspace: (string | number)[];
  }
}

declare global {
  interface Window {
    //@ts-ignore
    APP: ElementNode;
  }
}

const App = props => {
  const [isSplashVisible, setSplashVisible] = createSignal(true);

  useFocusManager({
    Announcer: ["a"],
    Menu: ["m"],
    //Escape: ["Escape", 27],
    Backspace: ["Backspace", 8],
    Left: ["ArrowLeft", 37],
    Right: ["ArrowRight", 39],
    Up: ["ArrowUp", 38],
    Down: ["ArrowDown", 40],
    Enter: ["Enter", 13],
    Escape: ["XF86Back", 10009],
  });
  // useMouse();
  const navigate = useNavigate();
  const announcer = useAnnouncer();
  announcer.debug = false;
  announcer.enabled = false;
  const location = useLocation();

  const generate12DigitId = (inputString: string) => {
    console.log(inputString);
    try {
      const hash = CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
      const shortHash = hash.substring(0, 12).toUpperCase();
      return (
        shortHash.substring(0, 2) +
        ":" +
        shortHash.substring(2, 4) +
        ":" +
        shortHash.substring(4, 6) +
        ":" +
        shortHash.substring(6, 8) +
        ":" +
        shortHash.substring(8, 10) +
        ":" +
        shortHash.substring(10, 12)
      );
    } catch (error) {
      console.error("Error generating ID:", error);
      return "No ID found"; // Trajtimi i gabimit sipas nevojave të aplikacionit tuaj
    }
  };

  createEffect(() => {
    const handleSplashEnd = () => {
      setSplashVisible(false);
    };
    setTimeout(handleSplashEnd, 7000);
  }, []);

  return (
    <>
      {isSplashVisible() ? (
        <View color={hexColor("#000000")}>
          <View x={600} y={300}>
            <SplashScreen />
          </View>
        </View>
      ) : (
        <View
          //@ts-ignore
          ref={window.APP}
          onAnnouncer={() => (announcer.enabled = !announcer.enabled)}
          onLast={() => history.back()}
          // onEnter={() => navigate("/text")}
          // onMenu={() => navigate("/")}
        >
          <View />
          {props.children}
        </View>
      )}
    </>
  );
};

export default App;
