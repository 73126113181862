import {
  createEffect,
  createSignal,
  //@ts-ignore
  Show,
  createSelector,
  //@ts-ignore
  For,
  onCleanup,
  onMount,
} from "solid-js";
import { View, Text, hexColor } from "@lightningtv/solid";
import { Column, Button, ButtonContainer, Row } from "@lightningtv/solid-ui";
import { useNavigate } from "@solidjs/router";
import axios from "axios";
import CryptoJS from "crypto-js";
import {
  isVpnMode,
  setHidePlaylistInfo,
  setIsPlaying,
  setIsVpnMode,
  setPlayListName,
  setPlayListPassword,
  setPlayListUrl,
  setPlayListUsername,
} from "../../state";
import QrCode from "../components/QRcode";
import QrCodeWithAxios from "../components/QRcodeaxios";
import styles from "../styles";
import packageJson from "../../package.json";
import { format } from "date-fns";

const ITEMS_PER_PAGE = 4; // Number of items to display at a time

const LicenceScreen = () => {
  let playlistColumnRef;
  let deleteColumnRef;
  let refreshRef;
  let cancelButtonRef;
  let okButtonRefrefreshRef;

  const [data, setData] = createSignal([]);
  const [deviceId, setDeviceId] = createSignal("");
  const [newToken, setNewToken] = createSignal("");
  const [currentIndex, setCurrentIndex] = createSignal(0);
  const [currentIndex2, setCurrentIndex2] = createSignal(0);
  const [response, setResponse] = createSignal(null);
  const [focusedIndex, setFocusedIndex] = createSignal(0);
  const [licenceEndDate, setLicenceEndDate] = createSignal(null);
  const [whiteList, setWhiteList] = createSignal(false);
  const [packageName, setPackageName] = createSignal("");
  const [loading, setLoading] = createSignal(true);
  const [isDialogOpen, setIsDialogOpen] = createSignal<boolean>(false);
  const [playlist, setPlaylist] = createSignal("");
  const [currentDeleteButtonIndex, setCurrentDeleteButtonIndex] = createSignal(0);

  const API_URL = "https://deviceapi.orbixplay.com/app/live-lbgjx/endpoint/getmydata";
  const API_TOKEN =
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RldmljZV9pZCI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsImJhYXNfZG9tYWluX2lkIjoiNjU0NDFiZjQ1OWRjYzYyNWI1YjY4ZGJlIiwiZXhwIjoxNzMwOTA1MDAzLCJpYXQiOjE3MzA5MDMyMDMsImlzcyI6IjY3MmI3Y2EzNjlhNGFlZWNkM2Y1MTdiZiIsImp0aSI6IjY3MmI3Y2EzNjlhNGFlZWNkM2Y1MTdjNSIsInN1YiI6IjY2ZWMxOWZkNDI5YWE0Nzg2NDNlOTU2YiIsInR5cCI6ImFjY2VzcyIsInVzZXJfZGF0YSI6eyJfaWQiOiI2NmVjMTlmZDQyOWFhNDc4NjQzZTk1NzgiLCJtb25nb191c2VyX2lkIjoiNjZlYzE5ZmQ0MjlhYTQ3ODY0M2U5NTZiIiwiZGV2aWNlaWQiOiI4QToyQzoxNjpGMTpBODpBMCIsImNyZWF0ZWRfYXQiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzI2NzQ5MTgxNTgwIn19LCJwbGF5bGlzdHMiOltdLCJsaWNlbnNlX2VuZF9kYXRlIjp7IiRkYXRlIjp7IiRudW1iZXJMb25nIjoiMTczMDM4MTU4MDAwMCJ9fSwidnBuX2xpY2Vuc2VfZW5kX2RhdGUiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzMyNjI3OTgwMDAwIn19LCJwYWNrYWdlX25hbWUiOiJGcmVlIFRyaWFsIiwiaWQiOiI2NmVjMTlmZDQyOWFhNDc4NjQzZTk1NzgifX0.C014WUGRkw93DW1W-m0vVlMI6QhjcUQc84IkVIzWy4w";
  const navigate = useNavigate();

  const parseISODate = isoDateString => {
    return isoDateString ? new Date(isoDateString) : null;
  };

  const generate12DigitId = (inputString: string) => {
    console.log(inputString);
    try {
      const hash = CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
      const shortHash = hash.substring(0, 12).toUpperCase();
      return (
        shortHash.substring(0, 2) +
        ":" +
        shortHash.substring(2, 4) +
        ":" +
        shortHash.substring(4, 6) +
        ":" +
        shortHash.substring(6, 8) +
        ":" +
        shortHash.substring(8, 10) +
        ":" +
        shortHash.substring(10, 12)
      );
    } catch (error) {
      console.error("Error generating ID:", error);
      return "No ID found"; // Trajtimi i gabimit sipas nevojave të aplikacionit tuaj
    }
  };

  createEffect(() => {
    // Clear the specific item from localStorage
    localStorage.removeItem("moviesData");
    localStorage.removeItem("seriesData");
  });

  function getDeviceDetails() {
    const deviceDetails: any = {};

    // Get MAC address
    //@ts-ignore
    tizen.systeminfo.getPropertyValue("NETWORK", function (network) {
      deviceDetails.device_mac_address = network.macAddress || "";
    });

    // Get screen size
    //@ts-ignore
    tizen.systeminfo.getPropertyValue("DISPLAY", function (display) {
      deviceDetails.screensize = `${display.resolutionWidth}x${display.resolutionHeight}`;
    });

    // Get Tizen version as API level equivalent
    //@ts-ignore
    tizen.systeminfo.getPropertyValue("BUILD", function (tizenInfo) {
      deviceDetails.api_version = tizenInfo.version;
    });

    // Add other static or known values
    deviceDetails.app_name = "OrbixPlay";
    deviceDetails.os = "Tizen";
    // Set other properties as needed

    return deviceDetails;
  }

  createEffect(() => {
    const fetchData = async () => {
      //@ts-ignore
      var duid = webapis.productinfo.getDuid();
      try {
        const response = await axios.post("https://logindeviceapi.orbixplay.com", {
          deviceid: generate12DigitId(duid),
          // deviceid: "18:4C:84:AF:5B:97",

          orbixplay_id: generate12DigitId(duid),
          ...getDeviceDetails(),
          appid: "2",
        });
        setResponse(response.data);
        // Handle the response data if needed
      } catch (error) {
        console.error("Failed to fetch:", error);
        // You can add more error handling logic here if needed
      }
    };
    fetchData();
  }, []);

  createEffect(() => {
    const tedhenat = response();

    if (tedhenat) {
      //@ts-ignore
      setNewToken(tedhenat.access_token);
    }
  });

  const fetchPlaylistData = async () => {
    const tedhenat: any = response();
    setLoading(true);
    if (tedhenat) {
      try {
        const response = await axios.get(API_URL, {
          headers: {
            Authorization: `Bearer ${tedhenat.access_token}`,
          },
        });
        setData(response.data.playlists);
        setDeviceId(response.data.deviceid);
        setPackageName(response?.data?.package_name);
        localStorage.setItem("licenceData", JSON.stringify(response.data));
        localStorage.setItem("proxy_url", response.data.proxy_url);

        const licenceEndDateValue: any = parseISODate(response.data.license_end_date);
        const vpnLicenceEndDateValue: any = parseISODate(response.data.vpn_license_end_date);

        // Determine the latest end date
        const latestEndDate =
          licenceEndDateValue > vpnLicenceEndDateValue ? licenceEndDateValue : vpnLicenceEndDateValue;

        setLicenceEndDate(latestEndDate);
        setWhiteList(response.data.whitelist || false);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  createEffect(() => {
    fetchPlaylistData();
  }, [currentIndex2()]);

  const isFirst = createSelector(() => {
    return 0;
  });

  createEffect(() => {
    setIsPlaying(false);
  });

  const onDown = () => {
    if (data().length < 4) {
      if (focusedIndex() < data().length - 1) {
        setFocusedIndex(focusedIndex() + 1);
      } else if (currentIndex() + data().length < data().length) {
        setCurrentIndex(currentIndex() + 1);
      } else {
        refreshRef.setFocus();
      }
    } else {
      if (focusedIndex() < ITEMS_PER_PAGE - 1) {
        setFocusedIndex(focusedIndex() + 1);
      } else if (currentIndex() + ITEMS_PER_PAGE < data().length) {
        setCurrentIndex(currentIndex() + 1);
      } else {
        refreshRef.setFocus();
      }
    }
  };
  const onUp = () => {
    if (focusedIndex() > 0) {
      setFocusedIndex(focusedIndex() - 1);
    } else if (currentIndex() > 0) {
      setCurrentIndex(currentIndex() - 1);
    }
  };

  const onRight = () => {
    deleteColumnRef.children[focusedIndex()].setFocus();
  };

  const onLeft = () => {
    playlistColumnRef.children[focusedIndex()].setFocus();
  };

  // const handleDelete = (index, playList) => {
  //   const newData = data().filter((_, i) => i !== index);

  //   updatePlaylistsOnDb(playList);

  //   setData(newData);
  //   setCurrentIndex(0);
  //   setFocusedIndex(0);
  // };

  const handleDelete = playList => {
    const newData = data().filter((_, i) => i !== currentDeleteButtonIndex());
    setPlaylist(playList);
    setData(newData);
    setCurrentIndex(0);
    setFocusedIndex(0);
    updatePlaylistsOnDb(playList);
  };

  const setDeleteButtonIndex = (index, playList) => {
    setPlaylist(playList);
    setCurrentDeleteButtonIndex(index);
    setIsDialogOpen(true);
    cancelButtonRef.setFocus();
  };

  const updatePlaylistsOnDb = async (playlist: any) => {
    const tedhenat: any = response();

    try {
      const response = await fetch("https://deviceapi.orbixplay.com/app/live-lbgjx/endpoint/hideplaylist", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tedhenat.access_token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          playlistName: playlist.playlistName,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update playlist");
      }

      const data = await response.json();
      fetchPlaylistData();
      return data;
    } catch (error) {
      console.error("Error updating playlist:", error);
    }
  };

  const expiredLink = "https://orbixplay.com/";

  const changeIndex = async newIndex => {
    const tedhenat: any = data();
    if (!whiteList() && isLicenceExpired()) {
      console.log("Licence has expired. Cannot change index.");
      window.open(expiredLink, "_blank");
      return;
    }

    try {
      //@ts-ignore
      const response = await axios.get(
        `${tedhenat[newIndex].url}/player_api.php?username=${tedhenat[newIndex].username}&password=${tedhenat[newIndex].password}`,
      );

      setPlayListName(tedhenat[newIndex].playlistName);
      setHidePlaylistInfo(tedhenat[newIndex].hide_playlist_info);
      setPlayListPassword(tedhenat[newIndex].password);
      //@ts-ignore
      setPlayListUrl(tedhenat[newIndex].url);
      //@ts-ignore
      localStorage.setItem("url", tedhenat[newIndex].url);
      //@ts-ignore
      setPlayListUsername(tedhenat[newIndex].username);
      setCurrentIndex2(newIndex);
      navigate("/home");
      setIsPlaying(true);
    } catch (error) {
      console.error("Fetching data failed:", error);
    }
  };

  const [playlistStatus, setPlaylistStatus] = createSignal([]);
  const [asyncStatus, setAsyncStatus] = createSignal(false);

  const checkPlaylists = async () => {
    const tedhenat: any = data();
    const statusArray: any = [];

    for (let i = 0; i < tedhenat.length; i++) {
      try {
        // setAsyncStatus(false);
        const response = await axios.get(
          `${tedhenat[i].url}/player_api.php?username=${tedhenat[i].username}&password=${tedhenat[i].password}`,
        );

        console.log("ketu esgtr fetch status per pikat", response, response.status);

        if (response.status === 200) {
          setAsyncStatus(true);
          statusArray.push(true);
        } else {
          setAsyncStatus(true);
          statusArray.push(false);
        }
      } catch (error) {
        console.log("hyri ne errir", error);

        statusArray.push(false);
      }
    }

    setPlaylistStatus(statusArray);
  };

  createEffect(() => {
    checkPlaylists();
  });

  const isLicenceExpired = () => {
    // Check if the licence end date is not set
    if (!licenceEndDate()) {
      return true;
    }

    //@ts-ignore
    const endDate = new Date(licenceEndDate());

    // Ensure endDate is valid
    if (isNaN(endDate.getTime())) {
      console.error("Invalid licence end date format");
      return true; // Assuming invalid date means expired
    }

    // Get the current date
    const currentDate = new Date();

    // Compare the dates
    return endDate < currentDate;
  };

  const togglePrivacyMode = () => {
    if (isVpnMode() === false) {
      setIsVpnMode(true);
      localStorage.setItem("vpn", "on");
    } else {
      setIsVpnMode(false);
      localStorage.setItem("vpn", "off");
    }
  };

  const FallbackView = () => (
    <View>
      <Text style={{ fontSize: 32, fontWeight: "bold" }}>Instructions</Text>
      <Text y={70} style={{ fontSize: 30, fontWeight: "bold", color: hexColor("#FFA500FF") }}>
        Step 1: Scan the QR Code
      </Text>
      <Text y={115} style={{ fontSize: 20 }}>
        Locate the QR code on your right.{"\n"}
        Use your smartphone's camera or a QR code scanner app to scan it. This will take you to the next step.
      </Text>
      <Text y={190} style={{ fontSize: 30, fontWeight: "bold", color: hexColor("#FFA500FF") }}>
        Step 2: Enter Playlist Information
      </Text>
      <Text y={235} style={{ fontSize: 20 }}>
        After scanning the QR code, you'll be prompted to enter your playlist information.{"\n"}
        Make sure to fill in all the required fields.
      </Text>
      <Text y={300} style={{ fontSize: 30, fontWeight: "bold", color: hexColor("#FFA500FF") }}>
        Step 3: Return to the App
      </Text>
      <Text y={345} style={{ fontSize: 20 }}>
        Once you've entered your playlist details, you can go back to the app and continue your experience.
      </Text>
      <View
        style={styles.LicenseButton}
        y={450}
        height={90}
        borderRadius={20}
        autofocus={true}
        width={400}
        onEnter={() => {
          fetchPlaylistData();
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View x={30} y={31} width={30} height={30} src="https://i.ibb.co/xmPFS2y/refresh-page-option.png" />
          <Text fontSize={28} y={35} x={80} fontWeight={"bold"}>
            REFRESH
          </Text>
        </View>
      </View>
    </View>
  );

  return (
    <View color={hexColor("#000000")}>
      <View width={800} height={1000} margin={20} backgroundColor={hexColor("#ffffff44")} x={100} y={350}>
        <Show when={loading()}>
          <Text y={-100} style={{ color: hexColor("#a6a6a6FF"), fontSize: 40 }}>
            Loading...
          </Text>
        </Show>

        <Show when={!loading()}>
          <Show when={!isLicenceExpired()}>
            <Text y={-100} style={{ fontSize: 28, color: hexColor("#a6a6a6FF"), fontWeight: "bold" }}>
              PLAYLISTS
            </Text>
          </Show>
          <Show when={isLicenceExpired()}>
            <Text y={-200} style={{ fontSize: 40 }}>
              You don't have a Licence.
            </Text>
            <Text y={-150} x={5} style={{ fontSize: 22 }}>
              Scan to buy licence.
            </Text>
          </Show>
          <Show when={!isLicenceExpired()} fallback={<FallbackView />}>
            <View>
              <Show
                when={data().length > 0}
                fallback={<Text fontSize={26}>You don't have any playlist</Text>}
              >
                <Column
                  ref={el => {
                    playlistColumnRef = el;
                  }}
                  onDown={onDown}
                  onUp={onUp}
                  onRight={onRight}
                >
                  <For each={data().slice(currentIndex(), currentIndex() + ITEMS_PER_PAGE)}>
                    {(playlist: any, i) => (
                      <View
                        style={styles.LicenseButton}
                        width={790}
                        height={95}
                        onEnter={() => {
                          changeIndex(i() + currentIndex());
                        }}
                        autofocus={i() === focusedIndex()}
                        key={playlist.id}
                        borderRadius={20}
                      >
                        <View width={700} height={50}>
                          <Text y={10} style={{ fontSize: 26 }}>
                            {playlist.playlistName}{" "}
                          </Text>
                        </View>

                        <Show when={asyncStatus()}>
                          <Show
                            when={playlistStatus()[i() + currentIndex()]}
                            fallback={
                              <View width={30} height={30} color={hexColor("#ff0000")} borderRadius={15} />
                            }
                          >
                            <View width={30} height={30} color={hexColor("#00ff00")} borderRadius={15} />
                          </Show>
                        </Show>

                        {/* {asyncStatus() && (
                          <>
                            {playlistStatus()[i() + currentIndex()] ? (
                              <View width={30} height={30} color={hexColor("#00ff00")} borderRadius={15} />
                            ) : (
                              <View width={30} height={30} color={hexColor("#ff0000")} borderRadius={15} />
                            )}
                          </>
                        )} */}
                      </View>
                    )}
                  </For>
                </Column>
              </Show>
              <Row
                ref={el => {
                  refreshRef = el;
                }}
                scroll="none"
                onUp={() => playlistColumnRef.setFocus()}
                autofocus={data().length <= 0}
              >
                <View
                  onEnter={() => {
                    togglePrivacyMode();
                    fetchPlaylistData();
                  }}
                  style={styles.LicenseButton}
                  y={450}
                  height={90}
                  borderRadius={20}
                  width={400}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text fontSize={28} y={34} x={75} fontWeight={"bold"}>
                      PRIVACY MODE
                    </Text>
                    <View
                      x={20}
                      y={31}
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 40,
                        color: isVpnMode() ? hexColor("#00ff00") : hexColor("#777"),
                        marginRight: 10,
                      }}
                    />
                  </View>
                </View>

                <View
                  style={styles.LicenseButton}
                  y={450}
                  height={90}
                  borderRadius={20}
                  width={400}
                  onEnter={() => {
                    fetchPlaylistData();
                  }}
                >
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <View
                      x={30}
                      y={31}
                      width={30}
                      height={30}
                      src="https://i.ibb.co/xmPFS2y/refresh-page-option.png"
                    />
                    <Text fontSize={28} y={35} x={80} fontWeight={"bold"}>
                      REFRESH
                    </Text>
                  </View>
                </View>
              </Row>

              <View
                x={810}
                ref={el => {
                  deleteColumnRef = el;
                }}
                onLeft={onLeft}
                style={{ display: "flex", flexDirection: "column" }}
                gap={21}
                // onDown={()=> deleteColumnRef.children.setFocus()}
              >
                <For each={data().slice(currentIndex(), currentIndex() + ITEMS_PER_PAGE)}>
                  {(playlist, i) => (
                    <View
                      style={styles.LicenseButton}
                      onEnter={() => setDeleteButtonIndex(i() + currentIndex(), playlist)}
                      width={100}
                      height={95}
                      borderRadius={20}
                      //@ts-ignore
                      key={`${playlist.id}`}
                    >
                      <View height={50} width={50} src="https://i.ibb.co/w690c1G/recycle-bin.png"></View>
                    </View>
                  )}
                </For>
              </View>
            </View>
          </Show>
          <View>
            <Text style={{ fontSize: 24 }} y={585}>
              License
            </Text>
            {/* <Text y={620} style={{ fontSize: 20 }}>
              {isLicenceExpired() ? "Expired" : "Not Expired"}, {packageName()}
            </Text> */}
            <Show when={licenceEndDate()}>
              <Text y={620} style={{ fontSize: 16 }}>
                {packageName()} expires in: {format(new Date(`${licenceEndDate()}`), "dd MMMM yyyy, HH:mm a")}
              </Text>
            </Show>
          </View>
        </Show>
      </View>

      <Show when={!loading()}>
        <View width={500} height={500} margin={20} backgroundColor={hexColor("#ffffff44")} x={1200} y={300}>
          <View style={{ display: "flex", justifyContent: "center" }}>
            <Show when={!isLicenceExpired()}>
              <Text y={-100} style={{ fontSize: 24, color: hexColor("#a6a6a6FF") }}>
                SCAN TO ADD PLAYLIST
              </Text>
            </Show>
            <Show when={isLicenceExpired()}>
              <Text y={-100} style={{ fontSize: 24, color: hexColor("#a6a6a6FF") }}>
                SCAN TO BUY LICENCE
              </Text>
            </Show>
          </View>
          <Column>
            <Show when={newToken()}>
              <Show when={!isLicenceExpired()}>
                <View height={500}>
                  <QrCode api_token={newToken()} />
                </View>
              </Show>
              <Show when={isLicenceExpired()}>
                <View height={500}>
                  <QrCodeWithAxios api_token={newToken()} />
                </View>
              </Show>
            </Show>

            <Show when={data()}>
              <View display="flex" flexDirection="column" alignItems="center" y={520} gap={15}>
                <Show when={!isLicenceExpired()}>
                  <Text style={{ color: hexColor("#a6a6a6FF"), fontSize: 24 }}>or visit</Text>
                  <Text style={{ fontSize: 24 }}>https://orbixplay.com/mylist and enter this device id:</Text>
                </Show>
                <Text style={{ fontSize: 24, color: hexColor("#FFA500FF") }}>{deviceId()}</Text>
                <Text style={{ fontSize: 18, color: hexColor("#808080FF") }}>
                  Version {packageJson.version}
                </Text>
              </View>
            </Show>
          </Column>
        </View>
      </Show>

      <Show when={isDialogOpen()}>
        <View x={500} y={350} width={800} height={300} color={hexColor("#ffffff")} borderRadius={20}>
          <Text x={50} y={30} style={{ fontSize: 24, color: hexColor("#000000"), fontWeight: "bold" }}>
            Remove Playlist
          </Text>
          <Text x={50} y={100} style={{ fontSize: 24, color: hexColor("#000000") }}>
            Do you want to remove ?
          </Text>
          <View
            ref={el => {
              cancelButtonRef = el;
            }}
            x={430}
            y={220}
            style={{
              borderRadius: 15,
              color: hexColor("#C1C3C1"),
              focus: { color: hexColor("#128DD3") },
              transition: {
                scale: { duration: 250, easing: "ease-in-out" },
              },
              height: 45,
              width: 150,
            }}
            states={"active"}
            onEnter={() => {
              setIsDialogOpen(false);
              playlistColumnRef.setFocus();
            }}
            textColor={hexColor("#000000")}
            onRight={() => okButtonRefrefreshRef.setFocus()}
          >
            <Text x={40} y={15} style={{ fontSize: 18, color: hexColor("#000000") }}>
              CANCEL
            </Text>
          </View>
          <View
            ref={el => {
              okButtonRefrefreshRef = el;
            }}
            x={600}
            y={220}
            style={{
              borderRadius: 15,
              color: hexColor("#C1C3C1"),
              focus: { color: hexColor("#128DD3") },
              transition: {
                scale: { duration: 250, easing: "ease-in-out" },
              },
              height: 45,
              width: 150,
            }}
            states={"active"}
            onLeft={() => cancelButtonRef.setFocus()}
            onEnter={() => {
              handleDelete(playlist());
              setIsDialogOpen(false);
              playlistColumnRef.setFocus();
            }}
            textColor={hexColor("#000000")}
          >
            <Text x={60} y={15} style={{ fontSize: 18, color: hexColor("#000000") }}>
              OK
            </Text>
          </View>
        </View>
      </Show>
    </View>
  );
};
export default LicenceScreen;

// import {
//   createEffect,
//   createSignal,
//   //@ts-ignore
//   Show,
//   createSelector,
//   //@ts-ignore
//   For,
//   onCleanup,
//   onMount,
// } from "solid-js";
// import { View, Text, hexColor } from "@lightningtv/solid";
// import { Column, Button, ButtonContainer, Row } from "@lightningtv/solid-ui";
// import { useNavigate } from "@solidjs/router";
// import axios from "axios";
// import {
//   setIsPlaying,
//   setPlayListPassword,
//   setPlayListUrl,
//   setPlayListUsername,
// } from "../../state";
// import CryptoJS from 'crypto-js';
// import styles from "../styles";

// const ITEMS_PER_PAGE = 4; // Number of items to display at a time

// const FallbackView = () => (
//   <View>
//     <Text style={{ fontSize: 32, fontWeight: 'bold' }}>Instructions</Text>
//     <Text y={70} style={{ fontSize: 30, fontWeight: 'bold', color: hexColor("#FFA500FF") }}>Step 1: Scan the QR Code</Text>
//     <Text y={115} style={{ fontSize: 20 }} >
//       Locate the QR code on your right.{"\n"}
//       Use your smartphone's camera or a QR code scanner app to scan it. This will take you to the next step.
//     </Text>
//     <Text y={190} style={{ fontSize: 30, fontWeight: 'bold', color: hexColor("#FFA500FF") }}>Step 2: Enter Playlist Information</Text>
//     <Text y={235} style={{ fontSize: 20 }}>
//       After scanning the QR code, you'll be prompted to enter your playlist information.{"\n"}
//       Make sure to fill in all the required fields.
//     </Text>
//     <Text y={300} style={{ fontSize: 30, fontWeight: 'bold', color: hexColor("#FFA500FF") }}>Step 3: Return to the App</Text>
//     <Text y={345} style={{ fontSize: 20 }}>
//       Once you've entered your playlist details, you can go back to the app and continue your experience.
//     </Text>
//   </View>
// );

// const FirstPage = () => {
//   const [data, setData] = createSignal([]);
//   const [deviceId, setDeviceId] = createSignal("");
//   const [newToken, setNewToken] = createSignal("");
//   const [currentIndex, setCurrentIndex] = createSignal(0);
//   const [currentIndex2, setCurrentIndex2] = createSignal(0);
//   const [response, setResponse] = createSignal(null);
//   const [focusedIndex, setFocusedIndex] = createSignal(0);
//   const [licenceEndDate, setLicenceEndDate] = createSignal(null);
//   const [whiteList, setWhiteList] = createSignal(false);
//   const [packageName, setPackageName] = createSignal("");

//   const API_URL =
//     "https://deviceapi.orbixplay.com/app/live-lbgjx/endpoint/getmydata";
//   const API_TOKEN =
//     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJiYWFzX2RldmljZV9pZCI6IjAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMCIsImJhYXNfZG9tYWluX2lkIjoiNjU0NDFiZjQ1OWRjYzYyNWI1YjY4ZGJlIiwiZXhwIjoxNzMwNzE0NjIyLCJpYXQiOjE3MzA3MTI4MjIsImlzcyI6IjY3Mjg5NGY2NWM4ZjVmN2UwNjc3MTc1MyIsImp0aSI6IjY3Mjg5NGY2NWM4ZjVmN2UwNjc3MTc2MCIsInN1YiI6IjY2ZWMxOWZkNDI5YWE0Nzg2NDNlOTU2YiIsInR5cCI6ImFjY2VzcyIsInVzZXJfZGF0YSI6eyJfaWQiOiI2NmVjMTlmZDQyOWFhNDc4NjQzZTk1NzgiLCJtb25nb191c2VyX2lkIjoiNjZlYzE5ZmQ0MjlhYTQ3ODY0M2U5NTZiIiwiZGV2aWNlaWQiOiI4QToyQzoxNjpGMTpBODpBMCIsImNyZWF0ZWRfYXQiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzI2NzQ5MTgxNTgwIn19LCJwbGF5bGlzdHMiOltdLCJsaWNlbnNlX2VuZF9kYXRlIjp7IiRkYXRlIjp7IiRudW1iZXJMb25nIjoiMTczMDM4MTU4MDAwMCJ9fSwidnBuX2xpY2Vuc2VfZW5kX2RhdGUiOnsiJGRhdGUiOnsiJG51bWJlckxvbmciOiIxNzMyNjI3OTgwMDAwIn19LCJwYWNrYWdlX25hbWUiOiJGcmVlIFRyaWFsIiwiaWQiOiI2NmVjMTlmZDQyOWFhNDc4NjQzZTk1NzgifX0.eWpxaIGzR-YB3C3idrLcDcgRHrA_0Yezvb4rhi9jNW4";
//   const navigate = useNavigate();

//   // console.log("DUID: " + webapis.productinfo.getDuid());

//   const parseISODate = (isoDateString) => {
//     return isoDateString ? new Date(isoDateString) : null;
//   };

//   const generate12DigitId = (inputString: string) => {
//     console.log(inputString);
//     try {
//       const hash = CryptoJS.SHA256(inputString).toString(CryptoJS.enc.Hex);
//       const shortHash = hash.substring(0, 12).toUpperCase();
//       return shortHash.substring(0, 2) + ':' +
//         shortHash.substring(2, 4) + ':' +
//         shortHash.substring(4, 6) + ':' +
//         shortHash.substring(6, 8) + ':' +
//         shortHash.substring(8, 10) + ':' +
//         shortHash.substring(10, 12);
//     } catch (error) {
//       console.error("Error generating ID:", error);
//       return "No ID found"; // Trajtimi i gabimit sipas nevojave të aplikacionit tuaj
//     }
//   };

//   createEffect(() => {
//     // Clear the specific item from localStorage
//     localStorage.removeItem("moviesData");
//     localStorage.removeItem("seriesData");

//   });

//   createEffect(() => {
//     const fetchData = async () => {
//       //@ts-ignore
//       var duid = webapis.productinfo.getDuid();
//       try {
//         const response = await axios.post(
//           "https://logindeviceapi.orbixplay.com",
//           {
//             deviceid: generate12DigitId(duid),
//             // deviceid: "18:4C:84:AF:5B:97",

//             orbixplay_id: "18:4C:84:AF:5B:97",
//             device_mac_address: "",
//             app_name: "18:4C:84:AF:5B:97",
//             app_version: 2,
//             ntype: 1,
//             hdmi: true,
//             firmware: "DeviceInfo.getApplicationName()",
//             device_brand: "DeviceInfo.getBrand()",
//             screensize: "",
//             api_version: "DeviceInfo.getApiLevel()",
//             os: "Platform.OS",
//             appid: "APPID.id",
//           }
//         );
//         console.log("helloooooooooooooo", response.data);
//         setResponse(response.data);
//         // Handle the response data if needed
//       } catch (error) {
//         console.error("Failed to fetch:", error);
//         // You can add more error handling logic here if needed
//       }
//     };
//     fetchData();
//   }, []);

//   createEffect(() => {
//     const tedhenat = response();

//     if (tedhenat) {
//       console.log("keto jane ato", tedhenat);

//       //@ts-ignore
//       setNewToken(tedhenat.access_token);
//     }
//   });

//   const [loading, setLoading] = createSignal(true); // Loading state

//   createEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       const tedhenat: any = response();

//       try {
//         const response = await axios.get(API_URL, {
//           headers: {
//             Authorization: `Bearer ${API_TOKEN}`,
//           },
//         });
//         setData(response.data.playlists);
//         setDeviceId(response.data.deviceid);
//         console.log("responset qe dua", response.data);
//         setPackageName(response?.data?.package_name);
//         localStorage.setItem("licenceData", JSON.stringify(response.data));

//         const licenceEndDateValue: any = parseISODate(response.data.license_end_date);
//         const vpnLicenceEndDateValue: any = parseISODate(response.data.vpn_license_end_date);

//         // Determine the latest end date
//         const latestEndDate = licenceEndDateValue > vpnLicenceEndDateValue ? licenceEndDateValue : vpnLicenceEndDateValue;

//         setLicenceEndDate(latestEndDate);
//         console.log("latest end ", latestEndDate);

//         setWhiteList(response.data.whitelist || false);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false);  // Set loading to false after data is fetched
//       }
//     };

//     fetchData();
//   }, [currentIndex2()]);

//   const isFirst = createSelector(() => {
//     return 0;
//   });
//   createEffect(() => {
//     setIsPlaying(false);
//   });
//   const onCustomEnter = () => {
//     navigate("home");
//     setIsPlaying(true);
//   };
//   const onDown = () => {
//     if (focusedIndex() < ITEMS_PER_PAGE - 1) {
//       setFocusedIndex(focusedIndex() + 1);
//     } else if (currentIndex() + ITEMS_PER_PAGE < data().length) {
//       setCurrentIndex(currentIndex() + 1);
//     } else {
//       refreshRef.setFocus();
//     }
//   };
//   const onUp = () => {
//     if (focusedIndex() > 0) {
//       setFocusedIndex(focusedIndex() - 1);
//     } else if (currentIndex() > 0) {
//       setCurrentIndex(currentIndex() - 1);
//     }
//   };
//   const onRight = () => {
//     deleteColumnRef.children[focusedIndex()].setFocus();
//   };
//   const onLeft = () => {
//     playlistColumnRef.children[focusedIndex()].setFocus();
//   };
//   const handleDelete = (index) => {
//     const newData = data().filter((_, i) => i !== index);
//     setData(newData);
//     setCurrentIndex(0);
//     setFocusedIndex(0);
//   };
//   let playlistColumnRef;
//   let deleteColumnRef;
//   let refreshRef;

//   const expiredLink = "https://orbixplay.com/";

//   const changeIndex = async (newIndex) => {
//     const tedhenat = data();
//     if (!whiteList() && isLicenceExpired()) {
//       console.log("Licence has expired. Cannot change index.");
//       window.open(expiredLink, "_blank");
//       return;
//     }

//     try {
//       //@ts-ignore
//       const response = await axios.get(
//         `${tedhenat[newIndex].url}/player_api.php?username=${tedhenat[newIndex].username}&password=${tedhenat[newIndex].password}`
//       );

//       const newData = response.data;
//       //@ts-ignore
//       setPlayListPassword(tedhenat[newIndex].password);
//       //@ts-ignore
//       setPlayListUrl(tedhenat[newIndex].url);
//       //@ts-ignore
//       localStorage.setItem("url", tedhenat[newIndex].url);
//       //@ts-ignore
//       setPlayListUsername(tedhenat[newIndex].username);
//       setCurrentIndex2(newIndex);
//       navigate("home");
//       setIsPlaying(true);
//     } catch (error) {
//       console.error("Fetching data failed:", error);
//     }
//   };
//   const [playlistStatus, setPlaylistStatus] = createSignal([]);

//   const checkPlaylists = async () => {
//     const tedhenat = data();
//     const statusArray = [];

//     for (let i = 0; i < tedhenat.length; i++) {
//       try {
//         //@ts-ignore
//         const response = await axios.get(
//           `${tedhenat[i].url}/player_api.php?username=${tedhenat[i].username}&password=${tedhenat[i].password}`
//         );

//         if (response.status === 200) {
//           //@ts-ignore
//           statusArray.push(true);
//         } else {
//           //@ts-ignore
//           statusArray.push(false);
//         }
//       } catch (error) {
//         //@ts-ignore
//         statusArray.push(false);
//       }
//     }
//     setPlaylistStatus(statusArray);
//   };

//   createEffect(() => {
//     checkPlaylists();
//   });

//   const isLicenceExpired = () => {
//     // Check if the licence end date is not set
//     if (!licenceEndDate()) {
//       return true;
//     }

//     // Convert licenceEndDate to a Date object
//     const endDate = new Date(licenceEndDate());

//     // Ensure endDate is valid
//     if (isNaN(endDate.getTime())) {
//       console.error("Invalid licence end date format");
//       return true; // Assuming invalid date means expired
//     }

//     // Get the current date
//     const currentDate = new Date();

//     // Compare the dates
//     return endDate < currentDate;
//   };

//   // {isLicenceExpired() ? 'Available' : 'Not Available'}

//   return (
//     <View>
//       <View
//         width={800}
//         height={1000}
//         margin={20}
//         backgroundColor={hexColor("#ffffff44")}
//         x={100}
//         y={350}
//       >
//         <Show when={loading()}>
//           <Text y={-100} style={{ color: hexColor("#a6a6a6FF"), fontSize: 40 }}>
//             Loading...
//           </Text>
//         </Show>

//         <Show when={!loading()}>

//           <Show when={!isLicenceExpired()}>
//             <Text y={-100} style={{ color: hexColor("#a6a6a6FF") }}>
//               PLAYLISTS
//             </Text>
//           </Show>
//           <Show when={isLicenceExpired()}>
//             <Text y={-200} style={{ fontSize: 40 }}>
//               You don't have a Licence.
//             </Text>
//             <Text y={-150} x={5} style={{ fontSize: 22 }}>
//               Scan to buy licence.
//             </Text>
//           </Show>
//           <Show when={data().length > 0 && !(isLicenceExpired())} fallback={<FallbackView />}>
//             <View>
//               <Column
//                 ref={(el) => {
//                   playlistColumnRef = el;
//                 }}
//                 onDown={onDown}
//                 onUp={onUp}
//                 onRight={onRight}
//               >
//                 <For
//                   each={data().slice(
//                     currentIndex(),
//                     currentIndex() + ITEMS_PER_PAGE
//                   )}
//                 >
//                   {(playlist, i) => (
//                     <View
//                       style={{
//                         ...styles.LicenseButton,
//                       }}

//                       width={790}
//                       height={95}
//                       onEnter={() => {
//                         changeIndex(i() + currentIndex());
//                       }}
//                       autofocus={i() === focusedIndex()}
//                       key={playlist.id}
//                       borderRadius={20}
//                     >
//                       <View width={700}
//                         height={50}>
//                         <Text>
//                           {playlist.playlistName}{" "}
//                         </Text>
//                       </View>

//                       <View
//                         width={30}
//                         height={30}
//                         color={playlistStatus()[i() + currentIndex()] ? hexColor('#00ff00') : hexColor('#ff0000')}
//                         borderRadius={15}
//                       />
//                     </View>

//                   )}
//                 </For>
//               </Column>
//               <Row
//                 ref={(el) => {
//                   refreshRef = el;
//                 }}
//                 onUp={() => playlistColumnRef.setFocus()}
//               >
//                 <View
//                   style={styles.LicenseButton}
//                   y={450}
//                   height={100}
//                   borderRadius={20}
//                   width={400}
//                 >
//                   <View width={50} height={50} src="https://i.ibb.co/xmPFS2y/refresh-page-option.png" />
//                 </View>
//               </Row>
//               <View
//                 x={810}
//                 ref={(el) => {
//                   deleteColumnRef = el;
//                 }}
//                 onLeft={onLeft}
//                 style={{ display: "flex", flexDirection: "column" }}
//                 gap={21}
//               // onDown={()=> deleteColumnRef.children.setFocus()}
//               >
//                 <For
//                   each={data().slice(
//                     currentIndex(),
//                     currentIndex() + ITEMS_PER_PAGE
//                   )}
//                 >
//                   {(playlist, i) => (
//                     <View
//                       style={styles.LicenseButton}
//                       onEnter={() => handleDelete(i() + currentIndex())}
//                       width={100}
//                       height={95}
//                       borderRadius={20}
//                       //@ts-ignore
//                       key={`${playlist.id}`}
//                     >
//                       <View height={50} width={50} src="https://i.ibb.co/w690c1G/recycle-bin.png"></View>
//                     </View>
//                   )}
//                 </For>
//               </View>
//             </View>
//           </Show>
//           <View>
//             <Text y={580}>License</Text>
//             <Text y={620} style={{ fontSize: 20 }}>
//               {isLicenceExpired() ? "Expired" : "Not Expired"}, {packageName()}
//             </Text>
//             <Show when={licenceEndDate()}>
//               <Text y={660} style={{ fontSize: 20 }}>
//                 Expires , {`${licenceEndDate()}`}
//               </Text>
//             </Show>
//           </View>
//         </Show>

//       </View>

//       <Show when={!loading()}>

//         <View
//           width={500}
//           height={500}
//           margin={20}
//           backgroundColor={hexColor("#ffffff44")}
//           x={1200}
//           y={300}
//         >
//           <View style={{ display: "flex", justifyContent: "center" }}>

//             <Show when={!isLicenceExpired()}>
//               <Text y={-100} style={{ color: hexColor("#a6a6a6FF") }}>
//                 SCAN TO ADD PLAYLIST
//               </Text>
//             </Show>
//             <Show when={isLicenceExpired()}>
//               <Text y={-100} style={{ color: hexColor("#a6a6a6FF") }}>
//                 SCAN TO BUY LICENCE
//               </Text>
//             </Show>

//           </View>
//           <Column>

//             <Show when={newToken()}>
//               {/* <Show when={!isLicenceExpired()}>
//                 <QrCode api_token={newToken()} />
//               </Show>
//               <Show when={isLicenceExpired()}>
//                 <QrCodeWithAxios api_token={newToken()} />
//               </Show> */}
//             </Show>

//             <Show when={data()}>
//               <View
//                 display="flex"
//                 flexDirection="column"
//                 alignItems="center"
//                 y={520}
//                 gap={15}
//               >
//                 <Show when={!isLicenceExpired()}>
//                   <Text style={{ color: hexColor("#a6a6a6FF") }}>or visit</Text>
//                   <Text>
//                     https://orbixplay.com/mylist and enter this device id:
//                   </Text>
//                 </Show>
//                 <Text style={{ color: hexColor("#FFA500FF") }}>{deviceId()}</Text>
//                 {/* <Text style={{ fontSize: 18, color: hexColor("#808080FF") }}>
//                 Version 0.35
//               </Text> */}
//               </View>
//             </Show>
//           </Column>
//         </View>
//       </Show>
//     </View>
//   );
// };
// export default FirstPage;
