//@ts-ignore
import { createEffect, createSignal, onCleanup, For, createMemo, Show } from "solid-js";
import { View, Text, hexColor } from "@lightningtv/solid";
import { useNavigate, useParams } from "@solidjs/router";
import { Button, ButtonContainer, Column, Row } from "@lightningtv/solid-ui";
import { isVpnMode, setIsPlaying, setIsVpnMode } from "../../state";
import { createInfiniteScroll } from "../components/pagination";
import searchMovies from "../api/providers/searchMovies";
import LoadingIndicator from "./loading/loading";
import { Buffer } from "buffer";

const homeStyle: any = {
  borderRadius: 50,
  width: 550,
  height: 550,
  scale: 1,
  zIndex: 2,
  color: hexColor("#222222"),
  transition: { scale: { duration: 250, easing: "ease-in-out" } },
  border: { width: 0, color: 0x00000000 },
  focus: { scale: 1.03, color: hexColor("#128DD3") },
};

const button: any = {
  borderRadius: 20,
  scale: 1,
  zIndex: 2,
  color: hexColor("#222222"),
  transition: { scale: { duration: 250, easing: "ease-in-out" } },
  border: { width: 0, color: 0x00000000 },
  focus: { scale: 1.03, color: hexColor("#128DD3") },
};

export function ThumbnailHome(props) {
  return (
    <View style={homeStyle} onEnter={props.onEnter}>
      <View src={props.src} width={300} height={300} mountX={-0.4} mountY={-0.2}></View>
      <View mountX={-0.35} mountY={-0.8}>
        <Text style={{ fontSize: 48, fontWeight: "bold", color: hexColor("#b6c6d3") }}>
          {props.data.name}
        </Text>
      </View>
    </View>
  );
}

export function RowHome(props) {
  return (
    <Row autofocus {...props} scroll="none" gap={40}>
      <For each={props.items}>{item => <ThumbnailHome {...item} />}</For>
    </Row>
  );
}

const HomePage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [time, setTime] = createSignal(new Date());
  const updateTime = () => setTime(new Date());
  const interval = setInterval(updateTime, 1000);
  onCleanup(() => clearInterval(interval));
  // const [screenWidth] = createSignal(window.innerWidth);
  // const [screenHeight] = createSignal(window.innerHeight);

  const onEnterCustom = () => {
    navigate("/channel-menu");
  };
  let col1;
  let col2;
  createEffect(() => {
    setIsPlaying(false);
  });
  const liveTV = () => {
    navigate("/channel-menu");
    setIsPlaying(true);
  };
  const movies = () => {
    navigate("/movies");
    setIsPlaying(true);
  };
  const series = () => {
    navigate("/series");
    setIsPlaying(true);
  };
  const playlists = () => {
    navigate("/");
  };
  const menuItems = [
    { src: "https://i.ibb.co/261M4fV/tvicon.png", data: { name: "LIVE TV" }, onEnter: liveTV },
    {
      src: "https://i.ibb.co/gdydbkV/moviesicon.png",
      data: { name: "MOVIES" },
      onEnter: movies,
    },
    {
      src: "https://i.ibb.co/2FWYD3j/seriesicon.png",
      data: { name: "SERIES" },
      onEnter: series,
    },
  ];
  const bottomItems = [
    // { src: "./assets/privacy_mode.png", data: { name: "Privacy Mode" } },
    { src: "./assets/settings.png", data: { name: "S" } },
    // { src: "./assets/playlists.png", data: { name: "Playlists" } }
  ];

  const togglePrivacyMode = () => {
    setIsVpnMode(prev => {
      const newMode = !prev;
      localStorage.setItem("vpn", newMode ? "on" : "off");
      return newMode;
    });
  };

  const [isTimeoutReached, setIsTimeoutReached] = createSignal(false);

  const providerCategory = createMemo(() => {
    return createInfiniteScroll(
      //@ts-ignore
      searchMovies(params.filter || "all"),
    );
  });

  createEffect(() => {
    let timeoutId;
    const isEmptyCategory = localStorage.getItem("@emptyCategory") === "true";

    // Check if providerCategory has data
    if ((providerCategory() && providerCategory().pages().length > 0) || isEmptyCategory) {
      // Data is available, clear the timeout
      clearTimeout(timeoutId);
      setIsTimeoutReached(true); // Set your desired value to true
    } else {
      // Set a timeout to set isTimeoutReached to true after 15 seconds
      timeoutId = setTimeout(() => {
        setIsTimeoutReached(true);
      }, 15000);
    }

    // Cleanup function to clear the timeout
    onCleanup(() => clearTimeout(timeoutId));
  });

  return (
    <View
      color={hexColor("#000000")}
      onEscape={() => {
        navigate("/");
      }}
    >
      <Show
        when={isTimeoutReached()}
        fallback={
          <View x={800} y={400}>
            <View
              y={-200}
              x={-35}
              width={350}
              height={120}
              src={"https://i.ibb.co/8P73RcM/orbixplay-logo-2.png"}
            ></View>
            <LoadingIndicator isCategoriesIndicator={false} />
          </View>
        }
      >
        <View flexItem={false} x={30} y={15} zIndex={105}>
          <View y={30} x={30} src="https://i.ibb.co/8P73RcM/orbixplay-logo-2.png" width={280} height={85} />
        </View>
        <View y={70} x={1430}>
          <Text style={{ fontSize: 28 }}>
            {time().toDateString()} {time().toLocaleTimeString()}
          </Text>
        </View>
        <View>
          <View width={70} x={90} y={200} onDown={() => col2.setFocus()}>
            <RowHome items={menuItems} ref={col1} />
          </View>
        </View>
        <View y={800} onUp={() => col1.setFocus()}>
          <Row
            ref={col2}
            scroll="none"
            style={{
              display: "flex",
              justifyContent: "spaceBetween",
              flexDirection: "row",
            }}
          >
            <View
              onEnter={() => {
                togglePrivacyMode();
              }}
              style={button}
              mountX={-0.22}
              y={100}
              height={90}
              borderRadius={20}
              width={400}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text fontSize={30} y={35} x={80} fontWeight={"bold"}>
                  PRIVACY MODE
                </Text>
                <Show
                  when={isVpnMode()}
                  fallback={
                    <View
                      x={20}
                      y={32}
                      style={{
                        width: 30,
                        height: 30,
                        borderRadius: 40,
                        color: isVpnMode() ? hexColor("#00ff00") : hexColor("#777"),
                        marginRight: 10,
                      }}
                    />
                  }
                >
                  <View
                    x={20}
                    y={32}
                    style={{
                      width: 30,
                      height: 30,
                      borderRadius: 40,
                      color: isVpnMode() ? hexColor("#00ff00") : hexColor("#777"),
                      marginRight: 10,
                    }}
                  />
                </Show>
              </View>
            </View>
            <ButtonContainer
              style={button}
              y={100}
              width={170}
              mountX={-2.0}
              borderRadius={20}
              onEnter={() => {
                navigate("/settings");
              }}
            >
              <View src="https://i.postimg.cc/hvTWsvm4/settings.png" width={40} height={40}></View>
            </ButtonContainer>

            <View
              onEnter={() => playlists()}
              style={button}
              mountX={0.3}
              y={100}
              height={90}
              borderRadius={20}
              width={400}
            >
              <View style={{ flexDirection: "row", alignItems: "center" }}>
                <Text fontSize={30} y={35} x={80} fontWeight={"bold"}>
                  PLAYLISTS
                </Text>
                <View x={25} y={25} width={40} height={40} src="https://i.ibb.co/DYGFmHF/playlist.png" />
              </View>
            </View>

            {/* <Button
              style={button}
              y={100}
              width={400}
              mountX={0.26}
              borderRadius={20}
              onEnter={() => playlists()}
            >
              Playlists
            </Button> */}
          </Row>
        </View>
      </Show>
    </View>
  );
};
export default HomePage;
