import { createSignal, onMount, Show } from "solid-js";
import axios from 'axios';

const QrCodeWithAxios = (props) => {
  const [imageUrl, setImageUrl] = createSignal("");

  // Function to update the image URL
  const updateImageUrl = (newUrl) => {
    setImageUrl(newUrl);
  };

  // Function to fetch the image URL from the API using Axios
  const fetchImageUrlWithAxios = async () => {
    try {
      const response = await axios.get('https://eu-central-1.aws.data.mongodb-api.com/app/live-lbgjx/endpoint/getqrcode', {
        headers: {
          "Authorization": `Bearer ${props.api_token}`
        }
      });
      updateImageUrl(response.data.qr_payment_url);
    } catch (error) {
      console.error("Error fetching image URL with Axios:", error);
    }
  };

  // Fetch the image URL when the component mounts
  onMount(() => {
    fetchImageUrlWithAxios();
  });

  return (
    <Show when={imageUrl()}>
      <div>
        <img src={imageUrl()} alt="Add Playlist" />
      </div>
    </Show>
  );
};

export default QrCodeWithAxios;
