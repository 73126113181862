import { Text, View, hexColor } from "@lightningtv/solid";
const PrivacyPolicy = (props: any) => {
  const termOne =
    "1. Content Disclaimer: OrbixPlay is a platform designed to enhance your digital experience. However, it is important to note that OrbixPlay does not host, provide, or distribute video content. Our service is structured to support your digital journey, but we do not bear responsibility for the availability, quality, or content of video material, as this is outside the scope of our services.";
  const termTwo =
    "2. No Liability for Third-Party Content: OrbixPlay LLC is not liable for any third-party content you may access through our application. Your engagement with any external content, including its legality, reliability, or appropriateness, is solely at your own risk.";
  const termThree =
    "3. Jurisdiction and Compliance: OrbixPlay LLC operates under the jurisdiction of the United States. Users are responsible for ensuring their use of the application is in compliance with all local, state, and federal laws.";
  const termFour =
    "4. Pricing Policy: Prices for OrbixPlay’s services are subject to change at any time without prior notice. By accepting this agreement, you acknowledge and agree that pricing may vary and that any such changes will not affect your current service until the renewal of your subscription.";
  const termFive =
    '5. No Warranty: The OrbixPlay application and its services are provided "as is" without any express or implied warranties, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement.';
  const termSix =
    "6. Acceptance of Terms: By clicking Agree, you agree to be bound by the terms of this disclaimer and acknowledge that any violation may result in termination of your access to OrbixPlay services.";
  return (
    <View ref={props.ref} x={550} y={180} height={2000} width={2000} clipping>
      <Text x={350} style={{ fontSize: 30, color: hexColor("#ffffff") }}>
        ORBIXPLAY USER AGREEMENT AND DISCLAIMER
      </Text>
      <Text x={300} y={80} style={{ fontSize: 23, color: hexColor("#ffffff") }}>
        Before you proceed, please read and accept the following terms and conditions:
      </Text>
      <Text x={10} y={150} style={textStyle}>
        {termOne}
      </Text>
      <Text x={10} y={270} style={textStyle}>
        {termTwo}
      </Text>
      <Text x={10} y={360} style={textStyle}>
        {termThree}
      </Text>
      <Text x={10} y={445} style={textStyle}>
        {termFour}
      </Text>
      <Text x={10} y={560} style={textStyle}>
        {termFive}
      </Text>
      <Text x={10} y={640} style={textStyle}>
        {termSix}
      </Text>
    </View>
  );
};
const textStyle = {
  fontSize: 23,
  color: hexColor("#ffffff"),
  fontWeight: 400,
  lineHeight: 32,
  width: 1300,
  contain: "width",
};
export default PrivacyPolicy;
