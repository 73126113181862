import api from "..";
import { playListPassword, playListUrl, playListUsername } from "../../../state";
import {
  chunkArrayChannel,
  convertItemsToTilesChannel,
} from "../formatters/ItemFormatter";
let cache = new Map();
const leftoverTiles = new Map();

export default function (filter: string, category_id: string) {
  return (pageIndex: number): Promise<any> => {
    const username = playListUsername();
    const password = playListPassword();


    const favoriteChannels = localStorage.getItem("@favoriteChannels");
    let favoriteChannelsArray: any = favoriteChannels ? JSON.parse(favoriteChannels) : [];
    const url = `/player_api.php?username=${username}&password=${password}&action=get_live_streams&category_id=${category_id}`;
    if (cache.has(url)) {
      return cache.get(url);
    }
    if (category_id === '99999') {
      let chunks = chunkArrayChannel(favoriteChannelsArray);
      return Promise.resolve(chunks);
    }
    let result = api._get(url).then((channels) => {
      let results = channels;
      let tiles = (
        leftoverTiles.has(filter) ? leftoverTiles.get(filter) : []
      ).concat(convertItemsToTilesChannel(results));
      // Chunk tiles from the API
      let chunks = chunkArrayChannel(tiles);
      if (chunks[chunks.length - 1].length < 7) {
        leftoverTiles.set(filter, chunks.pop());
      } else {
        leftoverTiles.delete(filter);
      }
      return chunks;
    });
    cache.set(url, result);
    return result;
  };
}