import { createEffect, onCleanup, onMount } from "solid-js";
import videoPlayer from "../../videoPreview";

const SplashScreen = props => {
  let gifElement;

  // onMount(() => {
  //     // Set the rotation to a complete turn (360 degrees) without acceleration
  //     const animation = gsap.to(gifElement, {
  //         duration: 30, // Duration for a full rotation
  //         rotation: 360, // Complete rotation
  //         repeat: 2, // Repeat indefinitely
  //         ease: "linear", // Use linear easing for constant speed
  //     });
  //     onCleanup(() => {
  //         animation.kill();
  //     });
  // });

  onMount(() => {
    const videoUrl = `https://assets.orbixplay.com/Orbix%20Play%20tv.mp4`;

    videoPlayer
      .playVideo(videoUrl, "splash")
      .then(() => {
        console.log("Video is now playing.");
        onCleanup(() => {
          videoPlayer.onTimeUpdate(() => {});
        });
      })
      .catch(error => {
        console.error("Error playing video:", error);
      });

    onCleanup(() => {
      videoPlayer.destroy();
    });
  });

  return <></>;
};
export default SplashScreen;
