import { Show, Text, View, hexColor } from "@lightningtv/solid";
import {
  hidePlaylistInfo,
  playListName,
  playListPassword,
  playListUrl,
  playListUsername,
} from "../../../../state";
const Account = (props: any) => {
  return (
    <View ref={props.ref} x={600} y={100} height={400} width={2000} clipping>
      <Text style={{ fontSize: 35, color: hexColor("#ffffff") }}>Playlist Info</Text>
      <Show
        when={hidePlaylistInfo()}
        fallback={
          <>
            <View
              y={90}
              style={{
                width: 1200,
                height: 60,
                borderRadius: 10,
                color: hexColor("#222222"),
                transition: {
                  scale: { duration: 250, easing: "ease-in-out" },
                },
                focus: { color: hexColor("#128DD3") },
              }}
              clipping
            >
              <Text x={20} y={17} style={{ fontSize: 28, color: hexColor("#ffffff") }}>
                Playlist Name:{" "}
              </Text>
              <Text
                x={playListName().length < 7 ? 1100 : playListName().length > 15 ? 900 : 1000}
                y={17}
                style={{ fontSize: 28, color: hexColor("#ffffff") }}
              >
                {playListName().length > 15 ? `${playListName().substring(0, 15)}...` : playListName()}
              </Text>
            </View>
            <View
              y={170}
              style={{
                width: 1200,
                height: 60,
                borderRadius: 10,
                color: hexColor("#222222"),
                transition: {
                  scale: { duration: 250, easing: "ease-in-out" },
                },
                focus: { color: hexColor("#128DD3") },
              }}
              clipping
            >
              <Text x={20} y={17} style={{ fontSize: 28, color: hexColor("#ffffff") }}>
                Host:{" "}
              </Text>
              <Text
                x={playListUrl().length < 20 ? 1000 : playListUrl().length > 25 ? 600 : 880}
                y={17}
                style={{ fontSize: 28, color: hexColor("#ffffff") }}
              >
                {playListUrl().length > 25 ? `${playListUrl().substring(0, 25)}...` : playListUrl()}
              </Text>
            </View>
            <View
              y={250}
              style={{
                width: 1200,
                height: 60,
                borderRadius: 10,
                color: hexColor("#222222"),
                transition: {
                  scale: { duration: 250, easing: "ease-in-out" },
                },
                focus: { color: hexColor("#128DD3") },
              }}
              clipping
            >
              <Text x={20} y={17} style={{ fontSize: 28, color: hexColor("#ffffff") }}>
                Username:{" "}
              </Text>
              <Text
                x={playListUsername().length < 15 ? 1000 : playListUsername().length > 20 ? 800 : 900}
                y={17}
                style={{ fontSize: 28, color: hexColor("#ffffff") }}
              >
                {playListUsername().length > 20
                  ? `${playListUsername().substring(0, 15)}...`
                  : playListUsername()}
              </Text>
            </View>
            <View
              y={330}
              style={{
                width: 1200,
                height: 60,
                borderRadius: 10,
                color: hexColor("#222222"),
                transition: {
                  scale: { duration: 250, easing: "ease-in-out" },
                },
                focus: { color: hexColor("#128DD3") },
              }}
              clipping
            >
              <Text x={20} y={17} style={{ fontSize: 28, color: hexColor("#ffffff") }}>
                Password:{" "}
              </Text>
              <Text
                x={playListPassword().length < 15 ? 1000 : playListPassword().length > 20 ? 900 : 1000}
                y={17}
                style={{ fontSize: 28, color: hexColor("#ffffff") }}
              >
                {playListPassword().length > 20
                  ? `${playListPassword().substring(0, 15)}...`
                  : playListPassword()}
              </Text>
            </View>
          </>
        }
      >
        <View
          y={90}
          style={{
            width: 1200,
            height: 60,
            borderRadius: 10,
            color: hexColor("#222222"),
            transition: {
              scale: { duration: 250, easing: "ease-in-out" },
            },
            focus: { color: hexColor("#128DD3") },
          }}
          clipping
        >
          <Text x={20} y={17} style={{ fontSize: 28, color: hexColor("#ffffff") }}>
            Playlist Name:{" "}
          </Text>
          <Text
            x={playListName().length < 7 ? 1100 : playListName().length > 15 ? 900 : 1000}
            y={17}
            style={{ fontSize: 28, color: hexColor("#ffffff") }}
          >
            {playListName().length > 15 ? `${playListName().substring(0, 15)}...` : playListName()}
          </Text>
        </View>
      </Show>
    </View>
  );
};
export default Account;
