// import {setIsBuffering} from "../state";

// let avPlayer;
// let videoObjectElement;

// export const state = {
//   playingState: false,
// };

// const init = (element, zIndex = -1, width = "100vw", height = "100vh", context) => {
//   if (!videoObjectElement) {
//     // Create <object> element for AVPlayer
//     videoObjectElement = document.createElement("object");
//     videoObjectElement.setAttribute("type", "application/avplayer");

//     videoObjectElement.style.cssText = `
//       position: absolute;
//       top: ${context === "preview" ? "40%" : "0"};
//       left: ${context === "preview" ? "49%" : "0"};
//       z-index: ${zIndex};
//       width: ${width};
//       height: ${height};
//       background-color: black;
//       object-fit: contain;
//       transform: ${context === "preview" ? "translate(-50%, -50%)" : "none"};
//     `;

//     document.body.insertBefore(videoObjectElement, document.body.firstChild);

//     // Initialize AVPlayer
//     avPlayer = webapis.avplay;
//   }
// };

// // Sets the display area for AVPlayer to match video element
// const setDisplayArea = () => {
//   if (avPlayer) {
//     avPlayer.setDisplayRect(0, 0, window.innerWidth, window.innerHeight);
//     avPlayer.setDisplayMethod("PLAYER_DISPLAY_MODE_FULL_SCREEN");
//   }
// };

// // Load video and prepare AVPlayer
// const load = async (config) => {
//   if (!avPlayer) throw new Error("AVPlayer is not initialized");

//   if (!config || !config.streamUrl) throw new Error("Stream URL is missing");

//   try {
//     avPlayer.open(config.streamUrl);

//     // Set AVPlayer event listeners for monitoring playback
//     avPlayer.setListener({
//       onbufferingstart: () => {
//         console.log("Buffering started")
//         // setIsBuffering(true);
//       },
//       onbufferingcomplete: () => {
//         console.log("Buffering complete")
//         setIsBuffering(false);
//       },
//       onerror: (error) => console.error("AVPlayer error:", error),
//       onstreamcompleted: () => {
//         console.log("Stream completed");
//         state.playingState = false;
//       },
//     });

//     // Call prepare and set display area
//     await prepareAndPlayAVPlayer();

//   } catch (error) {
//     console.error("Error during AVPlayer load:", error);
//   }
// };

// // Prepare and play the AVPlayer
// const prepareAndPlayAVPlayer = async () => {
//   return new Promise((resolve, reject) => {
//     avPlayer.prepareAsync(() => {
//       try {
//         console.log("AVPlayer prepared successfully");
//         setIsBuffering(true)

//         // Set display area before starting playback
//         setDisplayArea();

//         // Start playing
//         avPlayer.play();
//         state.playingState = true;
//         resolve();
//       } catch (error) {
//         console.error("Error during AVPlayer play:", error);
//         reject(error);
//       }
//     });
//   });
// };

// const play = () => {
//   try {
//     if (avPlayer) {
//       // Check if player is in a ready state or has previously been paused
//       const state = webapis.avplay.getState();
//       if (state === "READY" || state === "PAUSED") {
//         avPlayer.play();
//       } else if (state === "IDLE") {
//         // Reload the video if the player is in idle state
//         avPlayer.prepareAsync(() => {
//           avPlayer.play();
//         });
//       }
//     } else {
//       console.error("AVPlayer instance not available for play");
//     }
//   } catch (error) {
//     console.error("Error while trying to play video:", error);
//   }
// };

// const pause = () => {
//   try {
//     if (avPlayer) {
//       const state = webapis.avplay.getState();
//       if (state === "PLAYING") {
//         avPlayer.pause();
//       }
//     } else {
//       console.error("AVPlayer instance not available for pause");
//     }
//   } catch (error) {
//     console.error("Error while trying to pause video:", error);
//   }
// };

// // Stop and close AVPlayer, removing video element
// const destroy = () => {
//   return new Promise((resolve, reject) => {
//     if (avPlayer) {
//       try {
//         avPlayer.stop();
//         avPlayer.close();
//         videoObjectElement.remove();
//         videoObjectElement = null;
//         avPlayer = null;
//         resolve(); // Resolve when the player is successfully destroyed
//       } catch (error) {
//         console.error("Error during AVPlayer destroy:", error);
//         reject(error); // Reject in case of an error
//       }
//     } else {
//       resolve(); // Resolve if AVPlayer is already null
//     }
//   });
// };

// const getDuration = () => {
//   if (!avPlayer) throw new Error("AVPlayer is not initialized");
//   const duration = avPlayer.getDuration();
//   if (!isFinite(duration)) throw new Error("Invalid duration value");
//   return duration;
// };

// const getCurrentTime = () => {
//   if (!avPlayer) throw new Error("AVPlayer is not initialized");
//   const time = avPlayer.getCurrentTime();
//   if (!isFinite(time)) throw new Error("Invalid current time value");
//   return time;
// };

// // Play video with custom display settings for preview mode
// export const playVideo = async (streamUrl, context) => {
//   let zIndex = -1;
//   let width = "100vw";
//   let height = "100vh";

//   if (context === "preview") {
//     zIndex = 1; // Change z-index for preview
//     width = "30vw"; // Set width for preview
//     height = "50vh"; // Set height for preview
//   }

//   init(null, zIndex, width, height, context);

//   await load({ streamUrl });
// };

// const seek = (time) => {
//   console.log('Seeking to time:', time);
//   var currentTime = webapis.avplay.getCurrentTime();
//   var newTime = currentTime + 15000;

//   if (!avPlayer) throw new Error("AVPlayer is not initialized");

//   try {
//     avPlayer.jumpForward(15000); // Convert time to a precise format
//   } catch (error) {
//     console.error("Error during AVPlayer seek:", error);
//   }
// };

// // Listen for time updates by polling every second (AVPlayer does not support timeupdate)
// const onTimeUpdate = (callback) => {
//   if (!avPlayer) throw new Error("AVPlayer is not initialized");

//   setInterval(() => {
//     callback(getCurrentTime());
//   }, 1000);
// };

// export default {
//   init,
//   load,
//   pause,
//   getCurrentTime,
//   getDuration,
//   seek,
//   onTimeUpdate,
//   state,
//   destroy,
//   playVideo,
//   play
// };

import { setIsBuffering } from "../state";

let avPlayer;
let videoObjectElement;
let seeking = false;

export const state = {
  playingState: false,
  isBuffering: false, // Track buffering state
};

const init = (element, zIndex = -1, width = "100vw", height = "100vh", context) => {
  if (!videoObjectElement) {
    // Create <object> element for AVPlayer
    videoObjectElement = document.createElement("object");
    videoObjectElement.setAttribute("type", "application/avplayer");

    videoObjectElement.style.cssText = `
      position: absolute; 
      top: ${context === "preview" ? "40%" : "0"};  
      left: ${context === "preview" ? "49%" : "0"}; 
      z-index: ${zIndex};
      width: ${width}; 
      height: ${height};
      background-color: black; 
      object-fit: contain;
      transform: ${context === "preview" ? "translate(-50%, -50%)" : "none"};  
    `;

    document.body.insertBefore(videoObjectElement, document.body.firstChild);

    // Initialize AVPlayer
    avPlayer = webapis.avplay;
  }
};

// Sets the display area for AVPlayer to match video element
const setDisplayArea = () => {
  if (avPlayer) {
    avPlayer.setDisplayRect(0, 0, window.innerWidth, window.innerHeight);
    avPlayer.setDisplayMethod("PLAYER_DISPLAY_MODE_FULL_SCREEN");
  }
};

const load = async config => {
  if (!avPlayer) throw new Error("AVPlayer is not initialized");
  if (!config || !config.streamUrl) throw new Error("Stream URL is missing");

  try {
    avPlayer.open(config.streamUrl);

    avPlayer.setListener({
      onbufferingstart: () => {
        console.log("Buffering started");
        setIsBuffering(true);
        // state.isBuffering = true;
      },
      onbufferingprogress: function (percent) {
        console.log("Buffering progress data : " + percent);
      },
      onbufferingcomplete: () => {
        console.log("Buffering complete");
        setIsBuffering(false);
        // state.isBuffering = false;
        // if (state.playingState) {
        avPlayer.play();
        // }
      },
      onerror: error => {
        console.error("AVPlayer error:", error);
        // handlePlaybackError(error);
      },
      oncurrentplaytime: function (currentTime) {
        console.log("Current Playtime : " + data1);
      },
      onevent: function (eventType, eventData) {
        console.log("event type error : " + eventType + ", data: " + eventData);
      },
      onerrormsg: function (eventType, eventMsg) {
        console.log("event type error : " + eventType);
        console.log("event Message : " + eventMsg);
      },
      onsubtitlechange: function (duration, text, data3, data4) {
        console.log("Subtitle Changed.");
      },
      ondrmevent: function (drmEvent, drmData) {
        console.log("DRM callback: " + drmEvent + ", data: " + drmData);
      },
      onstreamcompleted: function () {
        console.log("Stream Completed");
        state.playingState = false;
      },
    });

    // Prepare and play AVPlayer
    await prepareAndPlayAVPlayer();
  } catch (error) {
    console.error("Error during AVPlayer load:", error);
  }
};

// Prepare and play the AVPlayer
const prepareAndPlayAVPlayer = async () => {
  return new Promise((resolve, reject) => {
    avPlayer.prepareAsync(() => {
      try {
        console.log("AVPlayer prepared successfully");
        setIsBuffering(true);
        setDisplayArea();
        avPlayer.play();
        state.playingState = true;
        resolve();
      } catch (error) {
        console.error("Error during AVPlayer play:", error);
        reject(error);
      }
    });
  });
};

const handlePlaybackError = error => {
  // Implement retry logic or user feedback based on error type
  if (error.code === "NETWORK_ERROR") {
    console.error("Network error occurred, attempting to reload...");
    // Optionally implement a retry mechanism here
  }
};

const play = () => {
  try {
    if (avPlayer) {
      const currentState = webapis.avplay.getState();
      if (currentState === "READY" || currentState === "PAUSED") {
        avPlayer.play();
        state.playingState = true;
      } else if (currentState === "IDLE") {
        avPlayer.prepareAsync(() => {
          avPlayer.play();
          state.playingState = true;
        });
      }
    } else {
      console.error("AVPlayer instance not available for play");
    }
  } catch (error) {
    console.error("Error while trying to play video:", error);
  }
};

const pause = () => {
  try {
    if (avPlayer) {
      const currentState = webapis.avplay.getState();
      if (currentState === "PLAYING") {
        avPlayer.pause();
        state.playingState = false;
      }
    } else {
      console.error("AVPlayer instance not available for pause");
    }
  } catch (error) {
    console.error("Error while trying to pause video:", error);
  }
};

// Stop and close AVPlayer, removing video element
const destroy = () => {
  return new Promise((resolve, reject) => {
    if (avPlayer) {
      try {
        avPlayer.stop();
        avPlayer.close();
        videoObjectElement.remove();
        videoObjectElement = null;
        avPlayer = null;
        resolve(); // Resolve when the player is successfully destroyed
      } catch (error) {
        console.error("Error during AVPlayer destroy:", error);
        reject(error); // Reject in case of an error
      }
    } else {
      resolve(); // Resolve if AVPlayer is already null
    }
  });
};

const getDuration = () => {
  if (!avPlayer) throw new Error("AVPlayer is not initialized");
  const duration = avPlayer.getDuration();
  if (!isFinite(duration)) throw new Error("Invalid duration value");
  return duration;
};

const getCurrentTime = () => {
  if (!avPlayer) throw new Error("AVPlayer is not initialized");
  const time = avPlayer.getCurrentTime();
  if (!isFinite(time)) throw new Error("Invalid current time value");
  return time;
};

export const playVideo = async (streamUrl, context) => {
  let zIndex = -1;
  let width = "100vw";
  let height = "100vh";

  if (context === "preview") {
    zIndex = 1;
    width = "30vw";
    height = "50vh";
  }

  init(null, zIndex, width, height, context);

  await load({ streamUrl });
};

const seek = async position => {
  if (seeking) {
    console.log("Seek already in progress, waiting for it to complete.");
    return; // Avoid triggering another seek if one is already in progress
  }
  const time = avPlayer.getCurrentTime();
  var koha = time + position;

  if (!avPlayer) throw new Error("AVPlayer is not initialized");

  seeking = true; // Set seeking to true

  return new Promise((resolve, reject) => {
    avPlayer.seekTo(
      koha,
      () => {
        console.log(`Seek to ${position} ms succeeded`);
        seeking = false; // Reset seeking flag on success
        resolve();
      },
      error => {
        console.error(`Seek to ${position} ms failed:`, error);
        seeking = false; // Reset seeking flag on error
        reject(error);
      },
    );
  });
};

// Listen for time updates by polling every second (AVPlayer does not support timeupdate)
const onTimeUpdate = callback => {
  if (!avPlayer) throw new Error("AVPlayer is not initialized");

  setInterval(() => {
    callback(getCurrentTime());
  }, 1000);
};

export default {
  init,
  load,
  pause,
  getCurrentTime,
  getDuration,
  seek,
  onTimeUpdate,
  state,
  destroy,
  playVideo,
  play,
};
