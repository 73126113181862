import { useNavigate } from "@solidjs/router";
import { Text, View, hexColor } from "@lightningtv/solid";
import packageJson from "../../../package.json";
import { Show, createEffect, createSignal } from "solid-js";
import Subscriptions from "./components/Subscriptions";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Account from "./components/Acoount";
const UserInfo = () => {
  let accountRef;
  let subscriptionsRef;
  let languageRef;
  let policyRef;
  const navigate = useNavigate();
  const [accountFocus, setAccountFocus] = createSignal(false);
  const [subscriptionFocus, setSubscriptionFocus] = createSignal(false);
  const [languageFocus, setLanguageFocus] = createSignal(false);
  const [privacyPolicyFocus, setPrivacyPolicyFocus] = createSignal(false);
  createEffect(() => {
    accountRef.setFocus();
    setAccountFocus(true);
  });
  return (
    <View
      color={hexColor("#000000")}
      onEscape={() => {
        navigate("/home");
      }}
      width={3000}
    >
      <View
        ref={el => {
          accountRef = el;
        }}
        x={100}
        y={100}
        style={{
          width: 320,
          height: 60,
          borderRadius: 15,
          // color: hexColor('#222222'),
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          focus: { color: hexColor("#128DD3") },
        }}
        onDown={() => subscriptionsRef.setFocus()}
        onFocus={() => {
          setAccountFocus(true);
          setSubscriptionFocus(false);
          setLanguageFocus(false);
          setPrivacyPolicyFocus(false);
        }}
      >
        <Text x={30} y={18} style={{ fontSize: 25, color: hexColor("#ffffff") }}>
          Account
        </Text>
      </View>
      <View
        ref={el => {
          subscriptionsRef = el;
        }}
        x={100}
        y={170}
        style={{
          width: 320,
          height: 60,
          borderRadius: 15,
          // color: hexColor('#222222'),
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          focus: { color: hexColor("#128DD3") },
        }}
        onDown={() => policyRef.setFocus()}
        onUp={() => accountRef.setFocus()}
        onFocus={() => {
          setAccountFocus(false);
          setSubscriptionFocus(true);
          setLanguageFocus(false);
          setPrivacyPolicyFocus(false);
        }}
      >
        <Text x={30} y={18} style={{ fontSize: 25, color: hexColor("#ffffff") }}>
          Subscriptions
        </Text>
      </View>
      {/* <View
                ref={el => {
                    languageRef = el;
                }}
                x={100}
                y={240}
                style={{
                    width: 320,
                    height: 60,
                    borderRadius: 15,
                    //  color: hexColor('#222222'),
                    transition: {
                        scale: { duration: 250, easing: "ease-in-out" },
                    },
                    focus: { color: hexColor("#128DD3") },
                }}
                onDown={() => policyRef.setFocus()}
                onUp={() => subscriptionsRef.setFocus()}
                onFocus={() => {
                    setAccountFocus(false);
                    setSubscriptionFocus(false);
                    setLanguageFocus(true);
                    setPrivacyPolicyFocus(false);
                }}
            >
                <Text x={30} y={18} style={{ fontSize: 25, color: hexColor("#ffffff") }}>Languge</Text>
            </View> */}
      <View
        ref={el => {
          policyRef = el;
        }}
        x={100}
        y={240}
        // y={310}
        style={{
          width: 320,
          height: 60,
          borderRadius: 15,
          //color: hexColor('#222222'),
          transition: {
            scale: { duration: 250, easing: "ease-in-out" },
          },
          focus: { color: hexColor("#128DD3") },
        }}
        onUp={() => subscriptionsRef.setFocus()}
        onFocus={() => {
          setAccountFocus(false);
          setSubscriptionFocus(false);
          setLanguageFocus(false);
          setPrivacyPolicyFocus(true);
        }}
      >
        <Text x={30} y={18} style={{ fontSize: 25, color: hexColor("#ffffff") }}>
          Privacy & Policy
        </Text>
      </View>
      {/* separation line */}
      <View
        x={500}
        y={30}
        style={{
          borderLeftWidth: 0.5,
          height: 1020,
          width: 3,
          color: hexColor("#ffffff"),
        }}
      />
      {/* show focused components */}
      <Show when={accountFocus()}>
        <Account />
      </Show>
      <Show when={subscriptionFocus()}>
        <Subscriptions />
      </Show>
      <Show when={privacyPolicyFocus()}>
        <PrivacyPolicy />
      </Show>
    </View>
  );
};
export default UserInfo;
