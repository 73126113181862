import { IntrinsicNodeProps, View, Text, Dynamic } from "@lightningtv/solid";
import { For, splitProps } from "solid-js";
import styles, { buttonStyles } from "../styles";
import { Button, Column, Row } from "@lightningtv/solid-ui";
import { type Tile } from "./formatters/ItemFormatter";
import { LazyUp } from "@lightningtv/solid/primitives";
import { useNavigate } from "@solidjs/router";

export function ThumbnailMovie(props: any) {
  return (
    <View {...props} src="" style={styles.ThumbnailMovie}>
      <View src={props.src} x={20} y={10} style={{ width: 140, height: 200 }}></View>
      <Text x={300} y={40}>
        {props.data.name}
      </Text>
    </View>
  );
}

export function Thumbnail(props: any) {
  return (
    <Button
      style={{
        borderRadius: 15,
        color: 0x4f7f4f,
        focus: { color: 0x467ffa },
        transition: {
          scale: { duration: 250, easing: "ease-in-out" },
        },
        height: 105,
      }}
      states={"active"}
    >
      {props.data.num} -{" "}
      {props.data.name.length > 22 ? `${props.data.name.substring(0, 22)} ...` : props.data.name}
    </Button>
  );
}

// // @ts-ignore
// <View {...props} src="" style={styles.ThumbnailChannel} >
//   {/* <View src={props.src} x={30} y={16} style={{ width: 80, borderRadius: 20 }}></View> */}
//   {/* @ts-ignore */}
//   <Text x={80} y={40}>{props.data.num} - {props.data.name}</Text>
// </View>

export function ThumbnailRecomandation(props: IntrinsicNodeProps) {
  const navigate = useNavigate();

  return (
    //@ts-ignore
    <View
      onEnter={() => {
        navigate(`/entity/movie/${props.entityInfo.id}/${props.data.category_id}`);
      }}
      {...props}
      style={styles.ThumbnailRecomandation}
    ></View>
  );
}

export function FocusRing(props: IntrinsicNodeProps) {
  return <View {...props} style={styles.FocusRing} />;
}

export interface TileRowProps extends IntrinsicNodeProps {
  items: Tile[];
}

export function TileRow(props: TileRowProps) {
  const [local, others] = splitProps(props, ["items"]);

  return (
    // @ts-ignore
    <Row {...others} style={styles.Row}>
      {/* @ts-ignore */}
      <For each={local.items}>{item => <Thumbnail {...item} />}</For>
    </Row>

    // <LazyUp
    //     component={Row}
    //     direction="row"
    //     upCount={10}
    //     each={props.items}
    //     y={50}
    //     height={props.height}
    //   >
    //     {(item) => (
    //       <Dynamic component={typeToComponent[props.row.type]} {...item()} />
    //     )}
    //   </LazyUp>
  );
}

export function TileRowRecomandation(props: TileRowProps) {
  const [local, others] = splitProps(props, ["items"]);

  return (
    // @ts-ignore
    <Row {...others} style={styles.RowSeries}>
      {/* @ts-ignore */}
      <For each={local.items}>{item => <ThumbnailRecomandation {...item} />}</For>

      {/* <LazyUp
        component={Row}
        direction='row'
        each={local.items}
      >
       {(item) => <ThumbnailRecomandation {...item} />}
      </LazyUp> */}
    </Row>
  );
}

export function TileRowMovie(props: TileRowProps) {
  const [local, others] = splitProps(props, ["items"]);

  return (
    // @ts-ignore
    <Row {...others} style={styles.RowMovie}>
      {/* @ts-ignore */}
      <For each={local.items}>{item => <ThumbnailMovie {...item} />}</For>
    </Row>
  );
}

export function TileRowCategories(props: TileRowProps) {
  const [local, others] = splitProps(props, ["items"]);

  return (
    // @ts-ignore
    <Row {...others}>
      <For each={local.items}>{(item: any) => <Button>{item.category_name}</Button>}</For>
    </Row>
  );
}

export function ButtonS(props) {
  return (
    <View {...props} forwardStates style={buttonStyles.container}>
      <Text style={buttonStyles.text}>{props.children}</Text>
    </View>
  );
}
