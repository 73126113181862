import { createSignal, createEffect, Show } from 'solid-js';
import { Text, View } from '@lightningtv/solid';
import { playListPassword, playListUrl, playListUsername, setGlobalStream } from '../../../state';
import { ProgressBar } from '@lightningtv/solid-ui';
import axios from 'axios';

const OSD = (props: any) => {

    const [epgData, setEpgData] = createSignal<any>(null);
    const [screenWidth] = createSignal(window.innerWidth);

    createEffect(() => {

        const username = playListUsername();
        const password = playListPassword();
        const playList = playListUrl();

        setGlobalStream(props.channel.stream_id);

        axios.get(`${playList}/player_api.php?username=${username}&password=${password}&action=get_short_epg&stream_id=${props.channel.stream_id}`).then((response: any) => {
            setEpgData(response.data);
        }).catch(error => {
            setEpgData(null);
        });

    }, [props]);

    function calculateProgress(startTimeUnix: any, endTimeUnix: any) {
        let currentTime = Date.now() / 1000;
        let totalDuration = endTimeUnix - startTimeUnix;
        let elapsedDuration = currentTime - startTimeUnix;
        let progress = elapsedDuration / totalDuration;
        return Math.min(1, Math.max(0, progress));
    }

    return (
        <View>
            <View
                x={0}
                y={775}
                width={screenWidth()}
                height={300}
                // @ts-ignore
                style={{ opacity: 0.1, zIndex: 999, color: "0x4F7F4F", focus: { color: "grey" } }}
            >
                <Text x={60} y={40} style={{ fontSize: 70, fontWeight: "bold" }}>
                    {props.channel ? `${props.channel.num} ${props.channel.name}` : "Loading..."}
                </Text>
                <View y={100} x={45}>
                    <Show when={epgData() && epgData().epg_listings} fallback={<Text y={10} x={20}></Text>}>
                        {(data) => {
                            const firstProgram = atob(data()[0]?.title || "");
                            const secondProgram = atob(data()[1]?.title || "");
                            const startTime = data()[0]?.start_timestamp;
                            const endTime = data()[0]?.stop_timestamp;
                            let progress = calculateProgress(startTime, endTime);

                            let currentDate = new Date();
                            let dayOfWeek = currentDate.getDay();
                            let daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
                            let dayName = daysOfWeek[dayOfWeek];

                            let hours = currentDate.getHours();
                            let minutes = currentDate.getMinutes();
                            let formattedHours = hours < 10 ? '0' + hours : hours;
                            let formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
                            let timeString = `${formattedHours}:${formattedMinutes}`;

                            return (
                                <View x={20} y={20}>
                                    <Text style={{fontSize: 28}} y={10}>Now: {firstProgram || `Programs of ${props.channel.name}`}</Text>
                                    <Text style={{fontSize: 28}} y={70}>Next: {secondProgram || `Programs of ${props.channel.name}`}</Text>
                                    {/* @ts-ignore */}
                                    <ProgressBar progress={progress} borderRadius={5} height={10} width={1500} y={55} value={0} progressRadius={100} />
                                    <View style={{ justifyContent: 'center', alignItems: 'center' }} x={1600}>
                                        <Text style={{fontSize: 32}}>{dayName}</Text>
                                        <Text style={{fontSize: 32}} y={40}>{timeString}</Text>
                                    </View>
                                </View>
                            );
                        }}
                    </Show>
                </View>
            </View>
        </View>
    );
};

export default OSD;